import React, { useState, useEffect, useRef } from "react";
import "../Header/header.css";
import Container from "react-bootstrap/Container";
import { Nav, Navbar } from "react-bootstrap";
import logo from "../../component/Assets/images/imc_logo_2x.webp";
import logoTwo from "../../component/Assets/images/advance_logo_2x.webp";
import { Link, useLocation, withRouter } from "react-router-dom";
import back from "../Assets/images/backtotop.png";
import drocross from "../Assets/images/services/NavImages/x.png";
import { Link as ScrollLink } from "react-scroll";
import mobLogo2 from "../Assets/images/services/imc_logo_2_2x.webp";
import HeaderSearch from "./HeaderSearch";
import searchIcon from "../Assets/images/services/NavImages/searchIconNav.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import logoTwomob from "../Assets/images/moblogo.webp"

const Header = ({ data, subservice }) => {
  const [show, setShow] = useState(false);
  const [drop, setDrop] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [isDesktop, setIsDesktop] = useState(
    window.innerWidth >= 768 ? true : false
  );
  const [showIcon, setShowIcon] = useState(false);
  const location = useLocation();
  function myGreeting() {
    setShowButton(false);
  }

  let menuRef = useRef();
  let desRef = useRef();

  useEffect(() => {
    let desHandler = (e) => {
      if (drop && !desRef.current.contains(e.target)) {
        setShow(false);
        setExpanded(false);
        setDrop(false);
      }
    };
    document.addEventListener("mousedown", desHandler);
    return () => {
      document.removeEventListener("mousedown", desHandler);
    };
  });

  useEffect(() => {
    let handler = (e) => {
      if (show && !menuRef.current.contains(e.target)) {
        setShow(false);
        setExpanded(false);
        setDrop(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const updateDimensions = () => {
    setIsDesktop(window.innerWidth >= 768 ? true : false);
  };
  const showbtn = () => {
    setShow(!show);
    setExpanded(!expanded);
    setDrop(false);
  };
  const toggleIcons = () => {
    setShowIcon(!showIcon);
  };

  return (
    <>
      <div className="ticker-wrapper-h">
        <div className="news-ticker-h">
          <p>
            {" "}
            Call Or WhatsApp Us At +92 325 9400555 & +92 326 9400555 In Case Of Any Vascular &
            Surgical Emergency
          </p>
        </div>
      </div>
      <div className={showIcon ? `Advance_header` : `nav_padding_null`}>
        <Navbar collapseOnSelect bg="light" expand="md" expanded={expanded}>
          <Container>
            {location.pathname === "/faq" ||
            location.pathname === "/privacypolicy" ||
            location.pathname === "/termofuse" ? (
              ""
            ) : (
              <div className="mob_btn">
                <ScrollLink
                  to="contactus"
                  spy={true}
                  smooth={true}
                  duration={200}
                >
                  <button className="header_btn">Book An Appointment</button>{" "}
                </ScrollLink>
              </div>
            )}
            <div className={`${!isDesktop && showIcon ? `www` : `hhh`}`}>
              <div
                className={`  d-md-flex full_width_search_form ${
                  !isDesktop && showIcon ? `max_logo_wrapper` : `logosWrapper`
                }`}
              >
                {!isDesktop && showIcon ? (
                  <HeaderSearch showIcon={showIcon} setShowIcon={setShowIcon} />
                ) : (
                  <>
                    {!showIcon && (
                      <div
                        className={`${
                          location.pathname === "/search-result" && "notVisible"
                        }  only_icon_div d-md-none`}
                      >
                        <LazyLoadImage
                          delayTime={500}
                          src={searchIcon}
                          alt="search_icon"
                          onClick={toggleIcons}
                        />
                      </div>
                    )}
                    {/* <Navbar.Brand href="#">
                      <Link to="/">
                        <LazyLoadImage
                          delayTime={500}
                          src={isDesktop ? logo : logo}
                          className=" logo_img_1"
                          alt="logo"
                        />
                      </Link>
                    </Navbar.Brand> */}
                    {isDesktop ? (
                        <Navbar.Brand href="#">
                        <Link to="/">
                          <LazyLoadImage
                            delayTime={500}
                            src={logoTwo}
                            className=" logo_img_2"
                            alt="logo"
                          />
                        </Link>
                      </Navbar.Brand>
                    ):(
                      <Navbar.Brand href="#">
                      <Link to="/">
                        <LazyLoadImage
                          delayTime={500}
                          src={logoTwomob}
                          className=" logo_img_1"
                          alt="logo"
                        />
                      </Link>
                    </Navbar.Brand>
                    )}
                  
                  </>
                )}
              </div>
              <div
                className={
                  !isDesktop && showIcon
                    ? `auto_btn_wrapper`
                    : ` buttonsWrapper`
                }
              >
                <Navbar.Toggle aria-controls="navbarScroll" onClick={showbtn}>
                  <span
                    className={
                      show === false
                        ? "navbar-toggler-icon "
                        : "navbar-toggler-icon1"
                    }
                  ></span>
                </Navbar.Toggle>
              </div>
            </div>
            {location.pathname !== "/search-result" && (
              <div className="md_search">
                <HeaderSearch />
              </div>
            )}
            <div className=""></div>
            <Navbar.Collapse id="navbarScroll" ref={menuRef}>
              <Nav
                className="me-auto my-2 my-lg-0 collapas"
                navbarScroll
                activeKey={location.pathname}
              >
                <div className="new_navbar ">
                  <LazyLoadImage
                    delayTime={500}
                    src={drocross}
                    alt="cross"
                    className="dropcross_img"
                    onClick={showbtn}
                  />
                </div>
                <Nav.Link
                  as={Link}
                  to="/"
                  onClick={() => {
                    setExpanded(false);
                    setShow(false);
                    setDrop(false);
                  }}
                >
                  <p
                    className={
                      location.pathname === "/"
                        ? "header_link_active"
                        : "header_link"
                    }
                  >
                    Home{" "}
                  </p>
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to="/about"
                  onClick={() => {
                    setExpanded(false);
                    setShow(false);
                    setDrop(false);
                  }}
                >
                  <p
                    className={
                      location.pathname === "/about"
                        ? "header_link_active"
                        : "header_link"
                    }
                  >
                    About Us
                  </p>
                </Nav.Link>
                <Nav.Link as={Link} to="/services">
                  <p
                    className={
                      drop === true
                        ? "header_link_drop"
                        : "header_link" && location.pathname === "/services"
                        ? "header_link_active"
                        : "header_link"
                    }
                    id="pics_yellow_drop"
                    onClick={() => {
                      setExpanded(false);
                      setShow(false);
                      setDrop(false);
                    }}
                  >
                    Services
                  </p>
                  <div
                    className={drop === true ? "drop_img1" : "drop_img"}
                    onClick={() => {
                      setDrop(!drop);
                    }}
                  ></div>
                  <div
                    className={drop === true ? "drop_img1_mob" : "drop_img_mob"}
                    onClick={() => {
                      setDrop(!drop);
                    }}
                  ></div>
                </Nav.Link>
                <div
                  className={drop === true ? "header_drop" : "header_drop1"}
                  ref={desRef}
                >
                  <Nav.Link
                    as={Link}
                    to="/services-detail/laparoscopic"
                    onClick={() => {
                      setExpanded(false);
                      setShow(false);
                      setDrop(false);
                    }}
                  >
                    <p className="drop_link">Advance Laparoscopic Surgery</p>
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/services-detail/endocrine"
                    onClick={() => {
                      setExpanded(false);
                      setShow(false);
                      setDrop(false);
                    }}
                  >
                    <p className="drop_link">Endocrine Surgery</p>
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/services-detail/vascular"
                    onClick={() => {
                      setExpanded(false);
                      setShow(false);
                      setDrop(false);
                    }}
                  >
                    <p className="drop_link">Peripheral Vascular Clinic</p>
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/services-detail/bariatric"
                    onClick={() => {
                      setExpanded(false);
                      setShow(false);
                      setDrop(false);
                    }}
                  >
                    <p className="drop_link">
                      Bariatric/Obesity (Weight Loss) Clinic
                    </p>
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/services-detail/cancercare"
                    onClick={() => {
                      setExpanded(false);
                      setShow(false);
                      setDrop(false);
                    }}
                  >
                    <h5 className="drop_link">Cancer Care Clinic</h5>
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/services-detail/healthcare"
                    onClick={() => {
                      setExpanded(false);
                      setShow(false);
                      setDrop(false);
                    }}
                  >
                    <p className="drop_link">Women's Healthcare Surgery</p>
                  </Nav.Link>
                </div>
                <Nav.Link
                  as={Link}
                  t
                  to="/blog"
                  onClick={() => {
                    setExpanded(false);
                    setShow(false);
                    setDrop(false);
                  }}
                >
                  <p
                    className={
                      location.pathname === "/blog"
                        ? "header_link_active"
                        : "header_link"
                    }
                  >
                    {" "}
                    Blogs
                  </p>
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to="/contact-us"
                  onClick={() => {
                    setExpanded(false);
                    setShow(false);
                    setDrop(false);
                  }}
                >
                  <p
                    className={
                      location.pathname === "/contact-us"
                        ? "header_link_1_active"
                        : "header_link_1"
                    }
                  >
                    Contact Us
                  </p>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>

            {location.pathname !== "/blog" &&
            !location.pathname.includes("/blogdetail/") &&
            location.pathname !== "/contact-us" &&
            location.pathname !== "/faq" &&
            location.pathname !== "/privacypolicy" &&
            location.pathname !== "/termofuse" ? (
              <ScrollLink
                to="contactus"
                spy={true}
                smooth={true}
                duration={200}
              >
                <button className="header_btn1">Book An Appointment</button>{" "}
              </ScrollLink>
            ) : (
              ""
            )}
          </Container>
        </Navbar>
      </div>
      {!isDesktop && showIcon && (
        <div className="lower_icon_div bg-light">
          <Container>
            <div className="d-flex justify-content-evenly mt-1" >
              {" "}
              <Navbar.Brand href="#">
                <Link to="/">
                  <LazyLoadImage
                    delayTime={500}
                    src={isDesktop ? logo : logoTwo}
                    className="lower_logo_1"
                    alt="logo"
                  />
                </Link>
              </Navbar.Brand>
              {/* <Navbar.Brand href="#">
                <Link to="/">
                  <LazyLoadImage
                    delayTime={500}
                    src={isDesktop ? logoTwo : mobLogo2}
                    className="lower_logo_2"
                    alt="logo"
                  />
                </Link>
              </Navbar.Brand> */}
            </div>
          </Container>
        </div>
      )}

      {showButton && (
        <div className="back-to-top">
          <button onClick={scrollToTop} className="top_btn">
            back to top <img src={back} alt="back" />
          </button>
        </div>
      )}
    </>
  );
};

export const HeaderWithRouter = withRouter(Header);
