import { useState } from "react";

const useValidationFunctions = () => {
  const [errors, setErrors] = useState({});
  const validateEmail = (e) => {
    let errors_ = { ...errors };
    let errorEmail = false;
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(e.target.value)) {
      errors_["email"] = "Invalid email address.";
      errorEmail = true;
    }
    if (errorEmail === false) {
      delete errors_["email"];
    }
    setErrors(errors_);
  };
  const validatePhone = (e) => {
    const regexPhoneNumber =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    let errors_ = { ...errors };
    if (e.target.value.match(regexPhoneNumber)) {
      delete errors_["phone"];
    } else {
      errors_["phone"] = "Invalid phone number.";
    }
    setErrors(errors_);
  };
  const checkNumbers = (event) => {
    let errors_ = { ...errors };
    if (!/^-?[0-9]/.test(event.key)) {
      errors_["numbers"] = "Enter Positive Numbers Only";
    } else {
      delete errors_["numbers"];
    }
    setErrors(errors_);
  };

  return {
    errors,
    setErrors,
    validateEmail,
    validatePhone,
    checkNumbers,
  };
};

export default useValidationFunctions;
