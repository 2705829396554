export const StaticResultData = {
  cardShapeData: [
    // Advance Laparoscopic Surgery Json
    {
      id: "03",
      path: "services-detail/laparoscopic",
      cardName: "Laparoscopic Hepatobiliary Surgery",
      cardData: [
        "Gall Stones",
        "Duct Stones",
        "Pancreatic Cancer",
        "Gall Bladder Cancer",
        "Liver Cancer",
        "Liver Cysts",
      ],
    },
    {
      id: "03",
      path: "services-detail/laparoscopic",
      cardName: "Laparoscopic Hernia Surgery",
      cardData: [
        "Inguinal Hernia (Groin Hernia)",
        "Belly Button Hernia (Umbilical Hernia)",
        "Hiatal Hernia (For Acid Reflux)",
        "Internal Hernias (For Intestinal Obstruction)",
        "Incisional Hernia (Post Open Surgery Hernia)",
        "Diaphragmatic Hernia (For Breathing Problems)",
      ],
    },
    {
      id: "03",
      path: "services-detail/laparoscopic",
      cardName: "Laparoscopic Appendix & Intestinal Surgery",
      cardData: [
        "Appendicectomy (Simple & Complicated)",
        "Ulcer Perforation",
        "Small Bowel Obstruction",
        "Large Bowel Cancer",
        "Large Bowel Ulcers & Polyps",
        "Rectal Prolapse & Bleeding",
      ],
    },
    {
      id: "03",
      path: "services-detail/laparoscopic",
      cardName: "Laparoscopic Solid Organ Surgery",
      cardData: [
        "Uterus & Ovaries",
        "Adrenal Glands",
        "Spleen",
        "Kidney Tumors",
        "Liver Tumors & Cysts",
        "Pancreatic Stones & Tumors",
      ],
    },
    {
      id: "04",
      path: "services-detail/laparoscopic",
      cardName: "Why Undergo Laparoscopic Surgery?",
      cardData: [
        "Better Cosmetic Results",
        "Small Incisions",
        "Less Post-Operative Pain",
        "No Huge Wounds",
        "Short Hospital Stay",
        "No Hernias",
        "Quick Return To Normal Activity",
        "Less Risk Of Complications",
        "Better Post-Surgery Body Image",
      ],
    },
    {
      id: "07",
      path: "services-detail/laparoscopic",
      cardName: "We Care For YOU",
      cardData: [
        "Highly-Experienced Surgeons",
        "Availability Of Female Surgeons",
        "Patient-Focused Care",
        "Comprehensive Diagnosis & Treatment",
        "Dedicated Post-Surgery Follow-Up",
        "Affability & Alacrity",
      ],
    },
    // Peripheral Vascular Surgery Json
    {
      id: "03",
      path: "services-detail/vascular",
      cardName: "Venous Diseases",
      cardData: [
        "DVT (Blood Clots In Deep Veins)",
        "Chronic Venous Insufficiency (Swollen Painful Leg)",
        "Venous Ulcers (Ulcers On the Ankle Due To Venous Hypertension)",
        "Varicose Veins (Swollen Blue Superficial Veins)",
        "Chronic Leg Aches In The Night",
        "Breathing Problems (Venous Clots/ Pulmonary Embolism)",
      ],
    },
    {
      id: "03",
      path: "services-detail/vascular",
      cardName: "Arterial Diseases",
      cardData: [
        "Leg/Arm Pain On Exertion (Arterial Narrowing)",
        "Leg/Arm Pain During Rest (Arterial Blockage)",
        "Sudden Acute Leg/Arm Pain (Arterial Closure Due to blood clot, embolism)",
        "Blue/Black Discolouration Of Arm/Leg (Impending Gangrene)",
        "Stoke/ Half-Body Paralysis (Narrowing Or Blockage Of Neck Arteries)",
        "Dilated/ Aneurysmal Disease Of Arteries (Bleeding & Haemorrhage Due To Rupture)",
      ],
    },
    {
      id: "04",
      path: "services-detail/vascular",
      cardName:
        "Who Are At The Risk Of Developing Peripheral Vascular Diseases?",
      cardData: [
        "Diabetics",
        "Hypertensives",
        "Chain Smokers",
        "Hyperlipidemics",
        "Cardiac Patients",
        "Morbidly Obese",
        "Increased Blood Viscosity",
        "Blood Clotting",
        "High Homocysteine Level In Blood",
      ],
    },
    {
      id: "06",
      path: "services-detail/vascular",
      cardName: "What Tests Are Requires To Get A Timely Diagnosis?",
      cardData: [
        "Blood Test For Cholesterol & Artery Diseases",
        "Duplex/Doppler Ultrasound of Blood Vessels",
        "Pulse Volume Recording",
        "Magnetic Resonance Angiography",
        "Computed Tomographic Angiography",
        "Ankle-Brachial Index",
        "Conventional Angiography/Angioplasty for Diagnosis/ Treatment Via State-Of-The-Art Cath Lab",
      ],
    },
    // Endocrine Surgery Json
    {
      id: "03",
      path: "services-detail/endocrine",
      cardName: "Thyroid Surgery",
      cardData: [
        "Thyroid Nodules",
        "Thyroid Cancer",
        "Hyper Functioning of Thyroid (Thyrotoxicosis)",
        "Thyroglossal Cyst",
        "Endoscopic Thyroid Surgery (POET)",
        "Recurrent Thyroid Disorders",
      ],
    },
    {
      id: "03",
      path: "services-detail/endocrine",
      cardName: "Parathyroid Surgery",
      cardData: [
        "Hyper Functioning (Hyperparathyroidism)",
        "Bone Cysts",
        "Kidney Stones",
        "Stomach Ulcers",
        "Resistant Psychiatric Disorders",
        "Parathyroid Cancers",
      ],
    },
    {
      id: "03",
      path: "services-detail/endocrine",
      cardName: "Laparoscopic Adrenal Surgery",
      cardData: [
        "Adrenal Tumors (Incidentaloma)",
        "Pheochromocytoma (Very High Blood Pressure)",
        "Cushing’s Syndrome (Lemon On Toothpick Appearance)",
        "Cancer Deposits In Adrenal Gland",
        "Hirsutism (Adrenal Malfunction)",
        "Sex Hormones Disorder (Adrenal Malfunction)",
      ],
    },
    {
      id: "06",
      path: "services-detail/endocrine",
      cardName: "What Is Our Approach To Treating Pancreatic Diseases?",
      cardData: [
        "Acute & Chronic Pancreatitis",
        "Pancreatic Pseudocyst",
        "Pancreatic Abscess",
        "Pancreatic Necrosis",
        "Annular Pancreas",
        "Pancreatic Divisum",
        "Carcinoma Of Pancreas",
        "Diabetes Mellitus",
        "Insulinomas",
        "Glucagonomas",
        "Gastrinomas",
      ],
    },
    // Cancer Care Clinic Json
    {
      id: "03",
      path: "services-detail/cancercare",
      cardName: "Breast Tumors/ Cancer",
      cardData: [
        "At Advance Surgicare, we believe in the preservation/ not removing breasts for cancers by offering patient-centric multi-modality (chemotherapy, radiotherapy, immunotherapy and breast conservation surgery) treatment options.",
        "While preserving/not removing the breasts, we stick to the basic principles of cancer surgeries.",
        "Strong radiological and lab backup (frozen section biopsy) helps us in removing cancer with clear margins and not the whole breast.",
        "Gamma Probe helps to preserve the Axilla/ Armpit (Sentinal Lymph Node Sampling) making the convalescence easy, with no arm swelling after the surgery (return to normal life within a week)",
      ],
    },
    {
      id: "03",
      path: "services-detail/cancercare",
      cardName: "Thyroid Cancer",
      cardData: [
        "Advance Surgicare offers surgery for all types of thyroid cancers.",
        "Advanced thyroid cancers which require removal of the lymph nodes (block dissection of the neck) are offered treatment via a multi-modality approach involving an endocrinologist and oncologist as well.",
        "For small thyroid nodules, especially in young people, we also offer endoscopic thyroidectomy (POET).",
        "Our thyroid clinic at Advance Surgicare also offers all sorts of medical management for thyroid disorders.",
      ],
    },
    {
      id: "03",
      path: "services-detail/cancercare",
      cardName: "Upper Abdominal Cancers",
      cardData: [
        "Laparoscopic diagnosis, staging, and cancer surgery for the following are being done at Advance Surgicare:",
        "Stomach & Duodenum. Most patients present with vomiting, weight loss, and anemia.",
        "Liver and gallbladder. Symptoms range from jaundice to abdominal masses.",
        "Pancreas and spleen. Symptoms range from surgical jaundice to abdominal lumps and bumps.",
      ],
    },
    {
      id: "03",
      path: "services-detail/cancercare",
      cardName: "Lower Abdominal Cancers",
      cardData: [
        "Lower abdominal cancers include tumors of the colon, rectum, appendix, and in ladies, uterus, and ovaries.",
        "Multi-modality treatment including complete laparoscopic removal of the above-mentioned tumors is being offered at Advance Surgicare with excellent outcomes.",
        "Oncological and gastroenterological backup makes Advance Surgicare a natural choice for the above-mentioned cancer resections.",
        "Radiology, pathology, and support for neo-adjuvant/ post-op chemotherapy/ radiation help the patient to return to normal life earlier than expected.",
      ],
    },
    {
      id: "04",
      path: "services-detail/cancercare",
      cardName: "SOPs Followed For Cancer Care",
      cardData: [
        "Screening Of Those Showing Symptoms",
        "Early Detection Of Cancer",
        "Staging To Determine Treatment Modality",
        "Resections As A Curing Modality.",
      ],
    },
    {
      id: "06",
      path: "services-detail/cancercare",
      cardName: "Cancer Screening Tests You Should Undergo",
      cardData: [
        "MRI & CT Scans",
        "Ultrasound",
        "Nuclear Medicine Scans",
        "Xrays & Other Radiographic Tests",
        "Mamograms",
        "Endoscopic Procedures",
        "Biopsy and Cytology Tests",
      ],
    },
    // Bariatric Surgery Json
    {
      id: "03",
      path: "services-detail/bariatric",
      cardName: "Diabetes",
      cardData: [
        "High Association With Obesity",
        "5-8 Kg Weight Gain Doubles Type 2 Diabetes Risk",
        "Bariatric Surgery Cures The Symptoms of Diabetes",
        "Patient Can Get Rid Of Diabetic Medication Including Insulin",
      ],
    },
    {
      id: "03",
      path: "services-detail/bariatric",
      cardName: "Heart Diseases",
      cardData: [
        "High Risk Of Heart Attacks",
        "Sudden Cardiac Death In Worst Cases",
        "Early Closure Of Coronary/Heart Arteries",
        "Solution To Prevent Heart Diseases Is Curing Obesity",
      ],
    },
    {
      id: "03",
      path: "services-detail/bariatric",
      cardName: "Hypertension",
      cardData: [
        "High Blood Pressure Is a Risk Factor Of Obesity",
        "Obesity Leads To Narrowing Of Arteries",
        "Narrowing Leads To High Blood Pressure",
        "Bariatric Surgery transfers the fat from arteries to the liver for metabolism, thus opening the arteries.",
      ],
    },
    {
      id: "03",
      path: "services-detail/bariatric",
      cardName: "Infertility",
      cardData: [
        "Obese women often face difficulty in getting pregnant",
        "Body fat affects the normal estrogen level",
        "To enhance the chances of getting pregnant, they need to lose weight.",
        "So, bariatric surgery improves the fertility/ pregnancy prospects",
      ],
    },
    {
      id: "03",
      path: "services-detail/bariatric",
      cardName: "Sleep Apnea",
      cardData: [
        "Breathing problems while sleeping is a common complication in overweight people.",
        "Fat deposition narrows the respiratory passages and weakens the breathing muscles.",
        "Very high risk of death dying while sleeping due to hypoxic (less oxygen) damage to the heart and lungs.",
        "Weight loss surgery is the definite cure/answer",
      ],
    },
    {
      id: "03",
      path: "services-detail/bariatric",
      cardName: "Cancer",
      cardData: [
        "Following cancers are related to being overweight (increased risk)",
        "Cancer of the uterus, gallbladder, colon, prostate, breast, and kidney are the ones.",
        "BMI of less than 35 (30 in the South Asian population) can lower the risk",
        "The leaner the person, the lower the risk of getting cancer",
      ],
    },
    {
      id: "03",
      path: "services-detail/bariatric",
      cardName: "Joint Pain",
      cardData: [
        "Being overweight gives you joint pains",
        "It decreases your mobility and negatively impacts the quality of life",
        "1 KG increase in weight, increases the risk of arthritis from 9-13%",
        "So weight loss surgery is the only solution even when you are planning for knee joint replacement surgery",
      ],
    },
    {
      id: "03",
      path: "services-detail/bariatric",
      cardName: "Decreases Life Span",
      cardData: [
        "Obese people have decreased lifespan",
        "Being overweight is associated with early death",
        "40-year old non-smoker females lose 7.1 years of their life",
        "40-year old non-smoker males lose 5.8 years of their life",
      ],
    },
    {
      id: "03",
      path: "services-detail/bariatric",
      cardName: "Other Health Risks",
      cardData: [
        "Other risks associated with obesity are as follows",
        "Heartburn, loss of urinary control, and early cancer of the oesophagus.",
        "Venous problem in the legs",
        "Backbone disc disease, resulting in chronic lower back pain.",
      ],
    },
    {
      id: "04",
      path: "services-detail/bariatric",
      cardName: "Why Do We Perform The Weight Loss Surgeries Laparoscopically?",
      cardData: [
        "Less Post-Surgery Pain",
        "Better Cosmetic Results",
        "Low Wound Infection Risk",
        "Early Return To Routine",
        "No Huge Incisions/ No Abdominal Hernias",
        "Short Hospital Stay",
      ],
    },
    {
      id: "06",
      path: "services-detail/bariatric",
      cardName: "Why Consider The Bariatric Surgery Option?",
      cardData: [
        "45 Kg Above Ideal Body Weight",
        "36 Kg Above Ideal Body Weight & Diabetic",
        "36 Kg Above Ideal Body Weight & Hypertensive",
        "30 Kg Above Ideal Body Weight Having Sleep Apnea",
      ],
    },
    // Women HealthCare Surgery Json
    {
      id: "03",
      path: "services-detail/healthcare",
      cardName: "Laparoscopic Gynaecological Services",
      cardData: [
        "Advance Surgicare offers advanced laparoscopic gynaecological services for the following women diseases.",
        "Disorders of the uterus resulting in painful/ massive menstruation (fibroids, endometrial thickening).",
        "Diseases of the ovaries and fallopian tubes (cysts and ectopic pregnancies)",
        "Laparoscopic surgeries for the cancer of the uterus, ovaries, and excision of the draining lymph nodes.",
      ],
    },
    {
      id: "03",
      path: "services-detail/healthcare",
      cardName: "Breast Related Problems",
      cardData: [
        "The lady surgeons at Advance Surgicare offer unmatched services for the following breast-related problems.",
        "Breast lumps, painful breasts, nipple discharge, and ulcers on the breast skin.",
        "Personalized breast care examination routine/ breast screening for high-risk breast cancer patients.",
        "Breast care guidance during the lactation/feeding for first-time mothers.",
      ],
    },
    {
      id: "03",
      path: "services-detail/healthcare",
      cardName: "Breast Conservation Surgery",
      cardData: [
        "Advance Surgicare’s lady surgeons are unique in offering breast cancer surgeries without the removal of the breasts.",
        "Women are offered minimally-invasive breast conservation surgeries with early rehabilitation to meet their needs.",
        "Besides breast conservation, the spread of tumors in the axilla/armpit is diagnosed via Gamma Probe during surgery.",
        "The Gamma Probe surgery (sentinel lymph node sampling) prevents the younger females from post-op arm swelling/pain.",
      ],
    },
    {
      id: "03",
      path: "services-detail/healthcare",
      cardName: "Surgeries For Pelvic Disorders & Piles Clinic",
      cardData: [
        "Lady surgeons offer the following surgeries for non-cancerous pelvic disorders in females.",
        "Chronic pelvic pain resulting in strained marital relationships.",
        "Loss of urinary control especially in elderly females (pelvic floor strengthening/hitching)",
        "Piles (hemorrhoids), bleeding and pain during passage of stools, and discharge of puss around the anus.",
      ],
    },
    {
      id: "04",
      path: "services-detail/healthcare",
      cardName: "Which Laparoscopic Pelvic Surgeries Do We Perform?",
      cardData: [
        "Lap Pelvic Sweep For Pelvic Cancer/Malignancies",
        "Lap Hysterectomy (Removal Of Uterus)",
        "Lap for Adnexal Mass (Biopsy & Removal)",
        "Ovarian Cystectomies (For Cancer & Infertility)",
        "Lap Procedures For Prolapse (Urinary Incontinence)",
        "Lymph Adenectomy (Pelvic Cancers)",
        "Lap Myomectomies For Uterine Fibroids",
        "Lap Management For Vaginal Vault Prolapse",
      ],
    },
    {
      id: "06",
      path: "services-detail/healthcare",
      cardName: "Which Tests Can Help Diagnose Breast Cancer?",
      cardData: [
        "Breast Imaging",
        "Mammography",
        "Ultrasound",
        "MRI",
        "Biopsy",
        "Receptor Assays",
        "Genetic Mapping",
      ],
    },
    // Services Page Json
    {
      id: "01",
      path: "services",
      cardName: "Advance Laparoscopic Surgery",
      cardData: [
        "Minimally invasive surgical treatments that leave minute scars on the body.",
      ],
    },
    {
      id: "01",
      path: "services",
      cardName: "Peripheral Vascular Surgery",
      cardData: [
        "Comprehensive surgical care for disorders of the arteries and veins.",
      ],
    },
    {
      id: "01",
      path: "services",
      cardName: "Endocrine Surgery",
      cardData: [
        "Focused on the minimally-invasive surgical treatment of thyroid, parathyroid, and adrenal glands.",
      ],
    },
    {
      id: "02",
      path: "services",
      cardName: "Cancer Care Clinic",
      cardData: [
        "Diagnosis and laparoscopic removal of the cancerous tumors in the body.",
      ],
    },
    {
      id: "02",
      path: "services",
      cardName: "Bariatric/Obesity Clinic (Weight Loss)",
      cardData: [
        "Promoting weight loss in morbidly obese (overweight) people through surgical treatment.",
      ],
    },
    {
      id: "02",
      path: "services",
      cardName: "Women's Healthcare Clinic",
      cardData: [
        "Lady surgeons providing personalized healthcare for women diseases (gynae and breasts).",
      ],
    },
    // About Us Page Json
    // {
    //   id: "01",
    //   path: "about",
    //   cardName: "OUR FOCUS IS ON YOU",
    //   cardData: [
    //     "Modern Technology",
    //     "Affordable Pricing",
    //     "Certified Doctor",
    //     "24/7 Sevice",
    //   ],
    // },
  ],

  paraShapeData: [
    // Advance Laparoscopic Surgery Json
    {
      id: "01",
      path: "services-detail/laparoscopic",
      heading: "OUR LAPAROSCOPIC SURGICAL APPROACH",
      para: "Laparoscopy is all about advancement in surgical procedures that we offer in the very best of setups at Advance Surgicare. No one likes having huge scars on their body post-surgery and risk of developing complications like wound infections and hernias. In that case, laparoscopy is the answer. Hence, we have established the highest quality of standards to provide minimally-invasive surgical care to our patients and improve their quality of life.",
    },
    {
      id: "02",
      path: "services-detail/laparoscopic",
      heading: "Surgeries We Perform Laparoscopically",
      para: "We use the advanced laparoscopic approach to perform many common surgeries that most surgeons perform via open surgery. It is one of our most preferred methods for treating many health problems in men and women because of the improved patient outcomes. Surgeries that are performed laparoscopically at Advance Surgicare include:",
    },
    {
      id: "04",
      path: "services-detail/laparoscopic",
      heading: "Why Undergo Laparoscopic Surgery?",
      para: "Laparoscopy is the art of viewing the body cavities, mainly the thorax and abdomen, without opening them up by making huge incisions. Surgeries are performed laparoscopically at Advance Surgicare by making small puncture wounds, termed ports. We prefer this surgical approach over open surgery because of the following reasons:",
    },
    {
      id: "05",
      path: "services-detail/laparoscopic",
      heading: "Patient-Centered Laparoscopic Surgical Care",
      para: "One of the most pertinent question that every patient asks is - why choose Advance Surgicare for undergoing laparoscopic surgery? The answer to this is simple. At Advance Surgicare, we have a state-of-the-art operation theatre setup with modern technology and gadgetry at our disposal that makes laparoscopy a success story for every surgical procedure; minor and major. Our anesthesia team in the ICU is also capable of caring for critically ill patients after major laparoscopic surgical interventions. Most importantly, our surgical expertise is rich in competence and experience, which makes it the backbone of our surgical services. Moreover, around the clock 24/7 availability of a consultant surgeon makes it a unique surgical facility.",
    },
    {
      id: "06",
      path: "services-detail/laparoscopic",
      heading: "What Special Conditions Merit Laparoscopic Approach?",
      para: "Laparoscopy is a surgical procedure that allows surgeons to access a body organ without making any large incisions. The surgeons at Advance Surgicare use the approach to perform surgeries on cancer and immune-compromised patients who cannot undergo traditional surgeries that involve making huge incisions. Moreover, this approach addresses the major cosmetic concern of patients by not leaving huge post-surgery scars on the body.",
    },
    {
      id: "07",
      path: "services-detail/laparoscopic",
      heading: "We Care For YOU",
      para: "Every surgeon at Advance Surgicare is dedicated to providing patients with personalized care. For decades, our aim has been the same - restoring the body functions of patients and improving their quality of life. The genuine concern and care we have for patients reflect in our principles.",
    },
    // Peripheral Vascular Surgery Json
    {
      id: "01",
      path: "services-detail/vascular",
      heading: "COMPREHENSIVE APPROACH TO TREATMENT",
      para: "Vascular conditions can keep you from living life to the fullest. Whether caused by an infection, injury, genetics, or any other condition, vascular diseases can cause serious health complications. The experienced vascular surgeons with an IR BACKUP at Advance Surgicare specialize in performing various minimally-invasive procedures to treat vascular catastrophes, saving patients from amputations (limb chopping), thus improving patients' quality of life.",
    },
    {
      id: "02",
      path: "services-detail/vascular",
      heading: "Vascular Conditions We Treat",
      para: "We (Peripheral Vascular Surgeon + IR Specialists) treat a spectrum of vascular diseases by performing interventions involving the arteries and veins of the abdomen, limbs, and neck.",
    },
    {
      id: "04",
      path: "services-detail/vascular",
      heading:
        "Who Are At The Risk Of Developing Peripheral Vascular Diseases?",
      para: "Vascular diseases include conditions that affect the blood vessels and circulatory system. They range from diseases of the lymph vessels to veins, arteries, and blood disorders affecting circulation. Mostly, the following people with specific conditions are at risk of vascular diseases such as:",
    },
    {
      id: "05",
      path: "services-detail/vascular",
      heading: "Surgical Care For Your Vascular Health",
      para: "The vascular surgeons and the IR Specialists at Advance Surgicare address patients with vascular conditions and deliver world-class vascular care through vascular interventions that leave no huge scars. Disorders of the vascular system are treated through medical therapy, interventional radiological procedures, and lastly, surgical reconstructions.",
    },
    {
      id: "06",
      path: "services-detail/vascular",
      heading: "What Tests Are Requires To Get A Timely Diagnosis?",
      para: "Time is of the essence when it comes to diagnosing and managing vascular diseases, which is why patients showing symptoms should get themselves assessed as soon as possible. The investigating modalities includes both, minimally invasive and non- invasive options. Mainly, the screening tests we recommend include:",
    },
    // Endocrine Surgery Json
    {
      id: "01",
      path: "services-detail/endocrine",
      heading: "ENDOCRINE CARE AT ADVANCE SURGICARE",
      para: "We are a one-stop clinic that treats hormonal and endocrine-related diseases in both - males and females. Our surgical care involves diagnosis, hormonal therapy, and definitive surgical treatment of endocrine diseases by a dedicated, experienced, and highly specialized surgical team. The highlight of our services is the availability of therapeutic, diagnostic, and surgical modalities, all under one roof to facilitate early diagnosis and prompt treatment.",
    },
    {
      id: "02",
      path: "services-detail/endocrine",
      heading: "Effective Endocrine Surgeries We Perform",
      para: "Endocrine surgeons at Advance Surgicare have expertise in clinically specialized aspects of the surgery. Patients suffering from endocrine diseases are cared for by surgeons who improve their quality of life. They perform mainly three types of endocrine surgeries.",
    },
    {
      id: "04",
      path: "services-detail/endocrine",
      heading: "Endocrine Surgeries Focused On YOU",
      para: "All the diagnostic and therapeutic modalities for the treatment of the above-mentioned adrenal gland diseases, parathyroid and thyroid malfunctions, including laparoscopic adrenalectomy, and endoscopic thyroidectomy (POET) are offered at Advance Surgicare, which is a blessing for the patients. It alleviates the need for opening the neck, and abdomen, or laparotomy as we call it, for surgical resection of the glands. Thus, avoiding large scars, hernias, and excessive pain after surgery, shortening the hospital stay, and allowing early return to normal life as a result.",
    },
    {
      id: "05",
      path: "services-detail/endocrine",
      heading: "What Is Our Approach To Treating Pancreatic Diseases?",
      para: "The pancreas serves both functions - exocrine, which involves secreting enzymes for the digestion of fat, proteins, carbohydrates, and endocrine, which involve the excretion of insulin and glucagon to control blood sugar level. The surgeons at Advance Surgicare specialize in the diagnosis and management of pancreatic diseases, including:",
    },
    // Cancer Care Clinic Json
    {
      id: "01",
      path: "services-detail/cancercare",
      heading: "COMPREHENSIVE TREATMENT MODALITIES FOR CANCEROUS TUMORS",
      para: "The goal behind oncological (cancer) care offered by specialists at Advance Surgicare (chemotherapy, radiotherapy, immunotherapy, surgical therapy) is to achieve a complete cure and enable the cancer survivor to live a normal life. These treatment modalities are decided as the best clinical option for the specific cancer/tumor. The availability of specialists like an experienced oncological surgeon, and an empathetic oncologist along with a rehabilitation expert makes our cancer clinic the best choice for your cancer woes.",
    },
    {
      id: "02",
      path: "services-detail/cancercare",
      heading: "Cancers We Treat",
      para: "The following cancers are offered with a comprehensive treatment plan at our cancer care clinic.",
    },
    {
      id: "04",
      path: "services-detail/cancercare",
      heading: "SOPs Followed For Cancer Care",
      para: "The diagnosis of cancer or cancer-causing tumors brings a flood of emotions and questions, including what to expect from the treatment. Without making as less disruptions as possible to the daily life of cancer patients,  our oncological surgeon Dr. Sadia and other oncologists at Advance Surgicare follow SOPs to provide them the best oncological care.",
    },
    {
      id: "05",
      path: "services-detail/cancercare",
      heading: "Laparoscopic Surgical Oncology For Cancer Patients",
      para: "Until recently, surgeons have had second thoughts about performing key-hole/Laparoscopic oncological surgeries on patients. However, at Advance Surgicare, we do not avoid this approach, as it is not only about avoiding huge incisions. Laparoscopy also helps in accurate diagnosis, staging, and palliation, which are crucial for making an effective cancer treatment plan for the patient. Through laparoscopic oncology, our surgeons take control of the veins and arteries first, after which the tumor is manipulated to cure the patient without any fear of the tumor spreading to different parts of the body.",
    },
    {
      id: "06",
      path: "services-detail/cancercare",
      heading: "Cancer Screening Tests You Should Undergo",
      para: "Screening tests are aimed at diagnosing cancer before it starts showing symptoms and when the chances of successful treatment are higher. Our oncologist Dr. Sadia and other oncological surgeons recommend these tests to the patients at Advance Surgicare to diagnose cancer early, determine if it is spreading, monitor it during their treatment and even after the oncological surgery.",
    },
    // Bariatric Surgery Json
    {
      id: "01",
      path: "services-detail/bariatric",
      heading: "TREAT & STOP UNCONTROLLABLE OBESITY",
      para: "Obesity is a chronic disease that involves the accumulation of excess body fat, sufficient to harm overall health. People whose Body Mass Index (BMI) is 30 (South Asian 25 or more) are considered obese. In case the BMI increases to 35 (South Asian 30 or more), it is termed Morbid Obesity, which is life-threatening. To treat uncontrollable obesity, we offer minimally invasive bariatric surgeries laparoscopically/ endoscopically.",
    },
    {
      id: "02",
      path: "services-detail/bariatric",
      heading: "Health Risks Of Obesity",
      para: "Many health problems are associated with obesity. In fact, the risks associated with the conditions are even more than the surgical treatment itself.",
    },
    {
      id: "04",
      path: "services-detail/bariatric",
      heading: "Why Do We Perform The Weight Loss Surgeries Laparoscopically?",
      para: "Most surgeons perform bariatric surgery through the conventional open method, which leaves huge incisions and scars on the patient's body. However, at Advance Surigcare, bariatric surgery is done laparoscopically by making small incisions. Our laparoscopic approach offers the following benefits over the conventional operative procedure:",
    },
    {
      id: "05",
      path: "services-detail/bariatric",
      heading: "Patient-Centered Approach To Bariatric Surgery",
      para: "The bariatric surgeries performed by surgeons at Advance Surgicare rely on two primary techniques that promote weight loss - intestinal malabsorption and gastric restriction. Hormonal changes are also an important post-surgery mechanism for weight loss. The alterations in the release of hormones causing hunger results in reduced hunger, early satiety, and other major health benefits.",
    },
    {
      id: "06",
      path: "services-detail/bariatric",
      heading: "Why Consider The Bariatric Surgery Option?",
      para: "People who are severely or morbidly obese are at high risk of major health problems and tend to live for a short life span. There are also other potential risks associated with a continuous increase in weight. Bariatric surgery has become an accepted clinical method to ensure long- term maintained weight loss in obese patients. The following persons are eligible for laparoscopic bariatric surgery:",
    },
    // Women HealthCare Surgery Json
    {
      id: "01",
      path: "services-detail/healthcare",
      heading: "WOMEN’S HEALTH IS OUR PRIORITY",
      para: "Women have unique healthcare requirements and providing them is our priority at Advance Surgicare. From regular screenings of predominantly female cancers to gynaecology care and breast health issues, we provide women with personalized attention. Surgical procedures performed by experienced lady surgeons are minimally-invasive, breast conservative, and leave no scars on their bodies and mind. Thus, improving their health, which is why they make us their partner in their wellness and cancer survivorship.",
    },
    {
      id: "02",
      path: "services-detail/healthcare",
      heading: "Specialized Women Care By Lady Surgeons",
      para: "The healthcare needs of women change, and different concerns need specialized treatment. Our female surgeons at Advance Surgicare address those concerns specific to women in a minimally-invasive/ breast-conservative way.",
    },
    {
      id: "04",
      path: "services-detail/healthcare",
      heading: "Which Laparoscopic Pelvic Surgeries Do We Perform?",
      para: "Surgeons at Advance Surgicare help women get relief from common health problems in the pelvic region. They use minimally invasive techniques to perform surgery, resulting in a short recovery and precise procedure. The following surgeries are performed on the ovaries, cervix, womb, and genital areas of women to treat their condition.",
    },
    {
      id: "05",
      path: "services-detail/healthcare",
      heading: "Exceptional Breast Care For Women",
      para: "Caring for women's breast health begins with understanding what is normal for the breasts. Any lumps in the breast, pain or nipple discharge are alarming and not normal. Lady surgeons at Advance Surgicare treat all kinds of breast-related problems in women through breast conservation and sentinel lymph node sampling. They are committed to helping women return to a healthy lifestyle post-surgery. ",
    },
    {
      id: "06",
      path: "services-detail/healthcare",
      heading: "Which Tests Can Help Diagnose Breast Cancer?",
      para: "Breast cancer in women is very common but treatable. To be aware of the abnormal signs in breasts, women have to be very observant so that the diagnosis can be made timely. To begin with, perform a self-exam while lying down, in the shower, or in front of a mirror. Check if there are any hard lumps in the breasts, thickened tissues, or changes in the appearance of the breasts, such as dimpling, rashes, or puckering of the skin. The following tests are recommended, with early initiation of the treatment:",
    },
    // Contact Us Page Json
    {
      id: "01",
      path: "contact-us",
      heading: "Need Help?",
      para: "Our surgeons are always available to help patients. Whether you are looking for medical advice or have any concerns related to surgery, contact us.",
    },
    // Services Page Json
    {
      id: "03",
      path: "services",
      heading: "SURGICAL CARE EXPERTISE",
      para: "Advance Surgicare is proud to offer exceptional surgical care services delivered in a compassionate and caring manner to improve the quality of life for patients.",
    },
    // About Us Page Json
    // {
    //   id: "01",
    //   path: "about",
    //   heading: "OUR FOCUS IS ON YOU",
    //   para: "Advance Surgicare was founded by Dr. Aasim Malik - Chief Surgeon, to provide patient- focused surgical care in Pakistan. Always determined to exceed expectations in the surgeries he performs, he has 20+ years of experience and grabs every opportunity to give patients a new life. At the premier laparoscopic and vascular surgery clinic, Dr. Aasim Malik and his team of surgeons guarantee personalized surgical care tailored just for YOU.",
    // },
    // {
    //   id: "02",
    //   path: "about",
    //   heading: "We Offer A Complete Range Of Surgical Services",
    //   para: "Undergoing surgery and choosing the right surgical clinic is equally important. When patients choose Advance Surgicare, we understand that they have trusted us with their surgical care needs. No matter which surgery you undergo, feel confident in the care we provide, knowing that we have a team of specialized surgeons who function using state-of-the-art surgical equipment and technology so that you recover at the earliest.",
    // },
    // {
    //   id: "02",
    //   path: "about",
    //   heading: "Changing The Way Surgeries Are Performed",
    //   para: "Dr. Aasim has devoted 20+ years to providing exceptional surgical care. The close collaboration between him and his team of surgeons at Advance Surgicare improves every patient's quality of life by using non-invasive surgical techniques like laparoscopy and keeps on discovering more ways to prevent and control their condition in the future. We believe in compassionate care, where no patients feel unrushed and reassured. While performing every surgery, we hold onto the highest clinical standards, keeping patient satisfaction our primary goal. When you walk to us for your treatment, know that you are in good hands.",
    // },
  ],
};
