import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import "../Privacy/privacy.css";
import { PrivacyData } from "./PrivacyData";
import listBullet from "../Assets/images/services/privacyListBullet.png";

const Privacy = () => {
  const location = useLocation();
  const { name } = useParams();
  const obj = PrivacyData[name];
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);
  return (
    <div className="privacy_term_main">
      <div className="privacy_term_data">
        <h5 className="main_heading_privacy">{obj?.Heading1}</h5>
        <p className="main_para">{obj?.Para1}</p>
        <h5 className="main_heading_privacy1">{obj?.Heading2}</h5>
        <p className="main_para">{obj?.Para2}</p>

        {name === "privacypolicy" && (
          <ul className="privacy_list_ul">
            <li>
              {" "}
              <span>
                <img
                  src={listBullet}
                  alt="list_bullet"
                  style={{ width: "9px", marginRight: "10px", height: "9px" }}
                />
              </span>{" "}
              {obj?.Li1}
            </li>
            <li>
              {" "}
              <span>
                <img
                  src={listBullet}
                  alt="list_bullet"
                  style={{ width: "9px", marginRight: "10px", height: "9px" }}
                />
              </span>{" "}
              {obj?.Li2}
            </li>
          </ul>
        )}

        <p className="main_para">{obj?.Subpara2}</p>

        <h5 className="main_heading_privacy1">{obj?.Heading3}</h5>
        <p className="main_para">{obj?.Para3}</p>
        <p className="main_para">{obj?.Subpara3}</p>
        <p className="main_para">{obj?.Sub2para3}</p>
      </div>
    </div>
  );
};

export default Privacy;
