import React, { useEffect } from "react";
import "./ServicesPage.css";
import { withRouter, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import srvcImg1 from "../Assets/images/services/LapSurgery.gif";
import srvcImg2 from "../Assets/images/services/PeriSurgery.gif";
import srvcImg3 from "../Assets/images/services/EndoSurgery.gif";
import srvcImg4 from "../Assets/images/services/CancerSurgery.gif";
import srvcImg5 from "../Assets/images/services/BariatricSurgery.gif";
import srvcImg6 from "../Assets/images/services/WomensSurgery.gif";
import AdvanceSurgicare from "../Surgicare/AdvanceSurgicare";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ServicesPage = ({}) => {
  const location = useLocation();

  useEffect(() => {
    let findElement = document?.getElementById(
      location?.state?.data
    )?.offsetTop;
    scrollToSpecificElement(findElement);
  }, [location.state]);
  const scrollToSpecificElement = (top) => {
    if (top) {
      window.scrollTo({
        top: top,
      });
    } else {
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      <div className="mobile_services_page">
        <div className="services_body">
          <div className="services_heading_body text-center" id="03">
            Surgical Care Expertise
          </div>

          <div className="services_description_body text-center">
            Advance Surgicare is proud to offer exceptional surgical care
            services delivered in a compassionate and caring manner to improve
            the quality of life for patients.
          </div>
          <div className="services_box_div s_box_1" id="01">
            <div className="services_body_box  d-flex flex-column align-items-center services_upper_left bg-white first_Carousel">
              <Link to="/services-detail/laparoscopic">
                {" "}
                <div className="services_box_heading  text-center  ">
                  Advance Laparoscopic Surgery{" "}
                </div>{" "}
              </Link>
              <div className="services_box_img_div">
                <LazyLoadImage
                  delayTime={500}
                  src={srvcImg1}
                  alt="Our Services"
                  className="services_box_img"
                />
              </div>
              <div className="services_box_description dp_1 text-center">
                Minimally invasive surgical treatments that leave minute scars
                on the body.
              </div>
              <Link to="/services-detail/laparoscopic">
                {" "}
                <button className="services_box_button ">
                  More Info &#62;
                </button>
              </Link>
            </div>
            <div className="services_body_box  d-flex flex-column align-items-center bg-white ">
              <Link to="/services-detail/vascular">
                <div className="services_box_heading  text-center ">
                  Peripheral Vascular Surgery{" "}
                </div>
              </Link>
              <div className="services_box_img_div">
                <LazyLoadImage
                  delayTime={500}
                  src={srvcImg2}
                  alt="Our Services"
                  className="services_box_img"
                />
              </div>
              <div className="services_box_description dp_2 text-center">
                Comprehensive surgical care for disorders of the arteries and
                veins.
              </div>
              <Link to="/services-detail/vascular">
                {" "}
                <button className="services_box_button ">
                  More Info &#62;
                </button>
              </Link>
            </div>
            <div className="services_body_box  d-flex flex-column align-items-center services_upper_right bg-white ">
              <Link to="/services-detail/endocrine">
                <div className="services_box_heading  text-center ">
                  Endocrine Surgery
                </div>{" "}
              </Link>
              <div className="services_box_img_div">
                <LazyLoadImage
                  delayTime={500}
                  src={srvcImg3}
                  alt="Our Services"
                  className="services_box_img"
                />
              </div>
              <div className="services_box_description dp_3 text-center">
                Focused on the minimally-invasive surgical treatment of thyroid,
                parathyroid, and adrenal glands.
              </div>
              <Link to="/services-detail/endocrine">
                {" "}
                <button className="services_box_button ">
                  More Info &#62;
                </button>
              </Link>
            </div>
          </div>
          <div className="services_box_div s_box_2" id="02">
            <div className="services_body_box  d-flex flex-column align-items-center services_bottom_left bg-white ">
              <Link to="/services-detail/cancercare">
                {" "}
                <div className="services_box_heading  text-center ">
                  Cancer Care Clinic
                </div>
              </Link>
              <div className="services_box_img_div">
                <LazyLoadImage
                  delayTime={500}
                  src={srvcImg4}
                  alt="Our Services"
                  className="services_box_img"
                />
              </div>
              <div className="services_box_description dp_4 text-center">
                Diagnosis and laparoscopic removal of the cancerous tumors in
                the body.
              </div>
              <Link to="/services-detail/cancercare">
                {" "}
                <button className="services_box_button ">
                  More Info &#62;
                </button>
              </Link>
            </div>
            <div className="services_body_box  d-flex flex-column align-items-center bg-white ">
              <Link to="/services-detail/bariatric">
                {" "}
                <div className="services_box_heading_bariatric  text-center ">
                  Bariatric/Obesity Clinic{" "}
                  <span className="bariatric_span"> &#40;Weight Loss&#41;</span>
                </div>
              </Link>
              <div className="services_box_img_div">
                <LazyLoadImage
                  delayTime={500}
                  src={srvcImg5}
                  alt="Our Services"
                  className="services_box_img"
                />
              </div>
              <div className="services_box_description dp_5 text-center">
                Promoting weight loss in morbidly obese &#40;overweight&#41;
                people through surgical treatment.
              </div>
              <Link to="/services-detail/bariatric">
                {" "}
                <button className="services_box_button ">
                  More Info &#62;
                </button>
              </Link>
            </div>
            <div className="services_body_box  d-flex flex-column align-items-center bg-white services_bottom_right  last_Carousel">
              <Link to="/services-detail/healthcare">
                {" "}
                <div className="services_box_heading  text-center ">
                  Women&#39;s Healthcare Clinic{" "}
                </div>{" "}
              </Link>
              <div className="services_box_img_div">
                <LazyLoadImage
                  delayTime={500}
                  src={srvcImg6}
                  alt="Our Services"
                  className="services_box_img"
                />
              </div>
              <div className="services_box_description dp_6 text-center">
                Lady surgeons providing personalized healthcare for women
                diseases &#40;gynae and breasts&#41;.
              </div>
              <Link to="/services-detail/healthcare">
                {" "}
                <button className="services_box_button ">
                  More Info &#62;
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <AdvanceSurgicare />
    </>
  );
};

export default withRouter(ServicesPage);
