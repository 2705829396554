import React, { useEffect } from "react";
import "./ServiceDetailPage.css";
import { useParams, withRouter, useLocation } from "react-router-dom";
import Faq from "../FAQS/Faqs";
import Appointment from "../Appointment/Appointment";
import Testtominal from "../Testominal/Testtominal";
import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconMarker from "../Assets/images/services/LayoutImages/arrowPointer.png";
import ticker from "../Assets/images/services/LayoutImages/Tick.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactPixel from "react-facebook-pixel";

const ServiceDetailPageContent = (props) => {
  let url = props.location.pathname;
  const [readmoretoggle, setReadnoretoggle] = useState(2);
  const [buttondisable, setButtondisable] = useState(true);
  const [index, setIndex] = useState();

  const { subservice } = useParams();

  const [services, setServices] = useState(props.data[subservice]);
  const location = useLocation();
  const obj = props.data[subservice];
  const readmoreClick = () => {
    setReadnoretoggle(obj.Array1.length);
    setButtondisable(false);
  };
  const readmoreClickless = () => {
    setReadnoretoggle(2);
    setButtondisable(true);
  };

  const [showText, setShowText] = useState(true);
  const [showText1, setShowText1] = useState(true);
  const [showText2, setShowText2] = useState(true);
  const [showText3, setShowText3] = useState(true);
  const [showText4, setShowText4] = useState(true);

  const toggleShowText = () => {
    setShowText(!showText);
  };
  const toggleShowText1 = () => {
    setShowText1(!showText1);
  };
  const toggleShowText2 = () => {
    setShowText2(!showText2);
  };
  const toggleShowText3 = () => {
    setShowText3(!showText3);
  };
  const toggleShowText4 = () => {
    setShowText4(!showText4);
  };

  useEffect(() => {
    let findElement = document?.getElementById(
      location?.state?.data
    )?.offsetTop;
    scrollToSpecificElement(findElement);
  }, [location.state]);

  const scrollToSpecificElement = (top) => {
    if (top) {
      setTimeout(
        window.scrollTo({
          top: top,
        }),
        1000
      );
    } else {
      window.scrollTo(0, 0);
    }
  };

  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = (id) => {
    const services_ = { ...services };
    for (let i = 0; i < services_.Array1.length; i++) {
      if (services_.Array1[i].id === id) {
        setIndex(id);
        services_.Array1[i].readMore = !services_.Array1[i].readMore;
        setServices(services_);
      }
    }
  };
  const sentDirectLink = () => {
    const phn = obj ? Number(obj.buttonPhoneNumber) : "03229400555";
    window.open(
      `https://api.whatsapp.com/send/?phone=${phn}&text=&type=phone_number&app_absent=0`,
      "_blank"
    );
  };

  const fbPixelContact = (number, type) => {
    ReactPixel.fbq("track", "Contact", {
      serviceType: obj.path,
      contactNumber: number,
      contactType: type,
    });
  };
  return (
    <>
      <div className="destop_servicesdetailpage_page">
        <div className="services_detail_body">
          <div className="destop_group_img" id="01">
            <div className="services_image_box">
              <div className={obj.DivsImage}></div>
            </div>
            <div className="destop_deatilpage_heading">
              <div className="services_image_text_container">
                <div className="services_image_text">
                  <div className="services_image_text_heading">
                    {obj.DivsTitle}
                  </div>
                  <div className="services_image_text_border"></div>
                  <div className="services_image_text_des mb-3">
                    <p
                      className="services_image_text_des"
                      dangerouslySetInnerHTML={{ __html: obj.DivsSubTitle }}
                    />
                  </div>
                  <a href={`tel:${obj.buttonPhoneNumber}`}>
                    <button
                      className="consult_btn"
                      onClick={(e) =>
                        fbPixelContact(obj.buttonPhoneNumber, "Consultation")
                      }
                    >
                      Call Now For Consultation
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="services_page_text_heading" id="02">
            {obj.Arr1Title}
          </div>
          <div>
            <p
              className="services_page_text_des"
              dangerouslySetInnerHTML={{ __html: obj.Arr1SubTitle }}
            />
          </div>
          <div className="content_div" id="03">
            <Row>
              {Object.keys(obj.firstArr).map((objItem, indexObj) => (
                <Row
                  className={`justify-content-center gap-md-3 gap-2 px-md-5 px-3 mx-md-auto sec_backgpourd_style ${
                    url === "/services-detail/vascular"
                      ? ""
                      : indexObj % 2
                      ? ""
                      : "sec_bg"
                  }`}
                >
                  {obj.firstArr[objItem].map((item, index) => (
                    <Col
                      key={index}
                      className={`dtl_arr_col col-6 pt-2 ${
                        url === "/services-detail/vascular"
                          ? ""
                          : indexObj % 2
                          ? ""
                          : "sec_bg"
                      }`}
                    >
                      <div className="d-flex flex-column  color_underline">
                        <p className="dtl_title">{item.title}</p>
                        <LazyLoadImage
                          delayTime={500}
                          src={item.image}
                          alt="surgery"
                          className="dtlSurgery_img align-self-center"
                        />
                        <ul className="zebra_sec_bullets">
                          {item.bulletsArr.map((item2, index) => (
                            <li className="dtl_list" key={index}>
                              <p dangerouslySetInnerHTML={{ __html: item2 }} />
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Col>
                  ))}
                </Row>
              ))}
            </Row>
          </div>

          <div
            className={`${
              url !== "/services-detail/bariatric" && "sec_bg"
            } dtl_2nd_section d-flex flex-md-row flex-column-reverse gap-2 py-4 px-5 `}
            id="04"
          >
            <div className="services_Detail_content">
              <div className="dtl_sec_left d-flex flex-column w-md-50 w-100  services_Detail_content_text">
                <p className="sec_title">{obj.Arr2Title}</p>
                <p className="sec_para">{obj.Arr2SubTitle}</p>
                <ul className="sec_list">
                  {obj.listView1 &&
                    obj.Array2.map((item, i) => (
                      <li key={i} className="sec_list_item">
                        {item.Title}
                      </li>
                    ))}
                </ul>
                <button
                  className="consult_btn"
                  onClick={(e) => {
                    sentDirectLink();
                    fbPixelContact(obj.buttonPhoneNumber, obj.Arr2Button);
                  }}
                >
                  {obj.Arr2Button}
                </button>
              </div>

              <div className="dtl_sec_right w-md-50 w-100">
                <LazyLoadImage
                  delayTime={500}
                  src={obj.Detailimage1}
                  alt="source_img"
                  className="dtl_sec_img img-sm-fluid"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className={`${
            url === "/services-detail/bariatric" ? "sec_bg" : ""
          } dtl_2nd_section d-flex flex-md-row-reverse flex-column-reverse gap-2 py-4 px-5 content_div `}
          id="05"
        >
          <div className="services_Detail_content1">
            <div className="dtl_sec_left d-flex flex-column w-md-50 w-100 services_Detail_content_text">
              <p className="sec_title">{obj.FlexTitle}</p>
              <p
                className="sec_para"
                dangerouslySetInnerHTML={{ __html: obj.FlexSubTitle }}
              />

              <ul className="sec_list">
                {obj.listView2 &&
                  obj.Array3.map((item, i) => (
                    <li key={i} className="sec_list_item">
                      {item.Title}
                    </li>
                  ))}
              </ul>
              <a href={`tel:${obj.buttonPhoneNumber}`}>
                <button
                  className="consult_btn"
                  onClick={(e) =>
                    fbPixelContact(obj.buttonPhoneNumber, obj.FlexButton)
                  }
                >
                  {" "}
                  {obj.FlexButton}
                </button>
              </a>
            </div>

            <div className="dtl_sec_right w-md-50 w-100">
              <LazyLoadImage
                delayTime={500}
                src={obj.Detailimage2}
                alt="source_img"
                className="dtl_sec2_img"
              />
            </div>
          </div>
        </div>

        {url !== "/services-detail/endocrine" && (
          <div
            className={`${
              url !== "/services-detail/bariatric" && "sec_bg"
            } dtl_2nd_section d-flex flex-md-row flex-column-reverse gap-2 py-4 px-5 content_div `}
            id="06"
          >
            <div className="services_Detail_content">
              <div className="dtl_sec_left d-flex flex-column w-md-50 w-100  services_Detail_content_text">
                <p className="sec_title">{obj?.Arr3Title}</p>
                <p className="sec_para">{obj?.Arr3SubTitle}</p>

                <ul className="sec_list">
                  {obj?.listView3 &&
                    obj?.Array3.map((item, i) => (
                      <li key={i} className="sec_list_item">
                        {item.Title}
                      </li>
                    ))}
                </ul>
                <a href={`tel:${obj.buttonPhoneNumber}`}>
                  <button
                    className="consult_btn"
                    onClick={(e) =>
                      fbPixelContact(obj.buttonPhoneNumber, obj.Arr3Button)
                    }
                  >
                    {obj.Arr3Button}
                  </button>
                </a>
              </div>

              <div className="dtl_sec_right w-md-50 w-100">
                <LazyLoadImage
                  delayTime={500}
                  src={obj?.Detailimage3}
                  alt="source_img"
                  className="dtl_sec2_img"
                />
              </div>
            </div>
          </div>
        )}

        <div className="last_dtl_sec content_div last_div_care" id="07">
          <div className="services_Detail_content ">
            <div className="gray_img d-flex flex-md-row flex-column-reverse gap-2 py-4 ">
              <div className="dtl_sec_left d-flex flex-column w-md-50 w-100">
                <p className="sec_title txt_clr_yellow last_care_para1">
                  {obj.lastTitle}
                </p>
                <p className="sec_para txt_clr_white last_care_para">
                  {obj.lastPara}
                </p>
                <ul className="floating_bullets">
                  {obj.lastBulletArray.map((item, i) => (
                    <li key={i} className="sec_list_item txt_clr_white">
                      <LazyLoadImage
                        delayTime={500}
                        style={{
                          width: "12px",
                          height: "9px",
                          marginRight: "13px",
                        }}
                        alt="pointer"
                        src={iconMarker}
                      />
                      <span> {item}</span>
                    </li>
                  ))}
                </ul>
                <a href={`tel:${obj.buttonPhoneNumber}`}>
                  <button
                    className="consult_btn"
                    onClick={(e) =>
                      fbPixelContact(obj.buttonPhoneNumber, "Consultation")
                    }
                  >
                    Call Now For Consultation
                  </button>
                </a>
              </div>

              <div className="dtl_sec_right w-md-50 w-100">
                <LazyLoadImage
                  delayTime={500}
                  src={obj.floatingImg}
                  alt="source_img"
                  className="floating_img "
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mobile_servicesdetail_page">
        <div className="services_detail_body">
          <div
            style={{
              padding: "0 7%",
            }}
          >
            <div className="servicesDetail_image_box" id="01">
              <div className={obj.DivsImage}></div>
            </div>
            <div className="services_image_text_container">
              <div className="services_image_text">
                <div className="services_image_text_heading">
                  {obj.DivsTitle}
                </div>
                <div className="services_image_text_border"></div>
                <div className="services_image_text_des mb-2">
                  {url !== "/services-detail/vascular" ? (
                    <>
                      <p
                        className="services_image_text_des"
                        style={{ display: "inline" }}
                        dangerouslySetInnerHTML={{
                          __html: showText4
                            ? obj.DivsSubTitle.slice(0, 240).concat("... ")
                            : obj.DivsSubTitle,
                        }}
                      />
                      <span onClick={toggleShowText4} className="text_span">
                        {showText4 && "Read more"}
                      </span>
                    </>
                  ) : (
                    <p
                      className="services_image_text_des"
                      dangerouslySetInnerHTML={{
                        __html: obj.DivsSubTitle,
                      }}
                    />
                  )}
                </div>
                <a href={`tel:${obj.buttonPhoneNumber}`}>
                  <button
                    className="consult_btn_mob"
                    onClick={(e) =>
                      fbPixelContact(obj.buttonPhoneNumber, "Consultation")
                    }
                  >
                    Call Now For Consultation
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            className="services_page_text_heading"
            style={{
              padding: "0% 7%",
            }}
            id="02"
          >
            {obj.Arr1Title}
          </div>
          <div
            className="services_page_text_des mb-2"
            style={{ padding: "0% 7%" }}
          >
            <p
              className="services_page_text_des"
              dangerouslySetInnerHTML={{ __html: obj.Arr1SubTitle }}
            />
          </div>
          <div id="03">
            {Object.keys(obj.firstArr).map((objItem, indexObj) =>
              obj.firstArr[objItem].map((item, index) => (
                <Row
                  className={`justify-content-center  mb-3 chips ${
                    index % 2 == 0 && ` dtl_bg_row`
                  }`}
                >
                  <Col key={index} className={`dtl_arr_col col-12 pt-2 my-2`}>
                    <div className="d-flex flex-column color_underline">
                      <p className="dtl_title_mob">{item.title}</p>
                      <LazyLoadImage
                        delayTime={500}
                        src={item.image}
                        alt="surgery"
                        className="dtlSurgery_img_mob align-self-center"
                      />
                      <ul className="zebra_sec_bullets">
                        {item.bulletsArr.map((item2, index) => (
                          <li className="dtl_list_mob " key={index}>
                            <LazyLoadImage
                              delayTime={500}
                              src={ticker}
                              className="ticker_img"
                            />
                            <span dangerouslySetInnerHTML={{ __html: item2 }} />
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Col>
                </Row>
              ))
            )}
          </div>

          <div
            className={` dtl_2nd_section mob_padding_div d-flex flex-column-reverse gap-2 py-4 sec_bg`}
            id="04"
          >
            <div className="dtl_sec_left d-flex flex-column w-md-50 w-100">
              <p className="sec_title">{obj.Arr2Title}</p>
              <p className="sec_para">
                {showText
                  ? obj.Arr2SubTitle.slice(0, 170).concat("... ")
                  : obj.Arr2SubTitle}
                <span onClick={toggleShowText} className="text_span">
                  {showText && "Read more"}
                </span>
              </p>
              <ul className="sec_list">
                {obj.listView1 &&
                  obj.Array2.map((item, i) => (
                    <li key={i} className="sec_list_item">
                      {item.Title}
                    </li>
                  ))}
              </ul>
              <button
                className="consult_btn_mob"
                onClick={(e) => {
                  sentDirectLink();
                  fbPixelContact(obj.buttonPhoneNumber, "Consultation");
                }}
              >
                Contact Us On WhatsApp
              </button>
            </div>

            <div className="dtl_sec_right w-md-50 w-100">
              <LazyLoadImage
                delayTime={500}
                src={obj.Detailimage1}
                alt="source_img"
                className="dtl_sec_img img-sm-fluid"
              />
            </div>
          </div>
        </div>

        <div
          className={`dtl_2nd_section d-flex flex-column-reverse gap-2 py-4 ps_div `}
          id="05"
        >
          <div className="dtl_sec_left d-flex flex-column w-md-50 w-100">
            <p className="sec_title">{obj.FlexTitle}</p>

            <div className="sec_para">
              <p
                className="sec_para"
                style={{ display: "inline" }}
                dangerouslySetInnerHTML={{
                  __html: showText1
                    ? obj.FlexSubTitle.slice(0, 170).concat("... ")
                    : obj.FlexSubTitle,
                }}
              />
              <span onClick={toggleShowText1} className="text_span">
                {showText1 && "Read more"}
              </span>
            </div>

            <ul className="sec_list">
              {obj.listView2 &&
                obj.Array3.map((item, i) => (
                  <li key={i} className="sec_list_item">
                    {item.Title}
                  </li>
                ))}
            </ul>
            <a href={`tel:${obj.buttonPhoneNumber}`}>
              <button
                className="consult_btn_mob"
                onClick={(e) =>
                  fbPixelContact(obj.buttonPhoneNumber, obj.FlexButton)
                }
              >
                {obj.FlexButton}
              </button>
            </a>
          </div>

          <div className="dtl_sec_right w-md-50 w-100">
            <LazyLoadImage
              delayTime={500}
              src={obj.Detailimage2}
              alt="source_img"
              className="dtl_sec2_img"
            />
          </div>
        </div>

        {url !== "/services-detail/endocrine" && (
          <div
            className="dtl_2nd_section d-flex flex-column-reverse gap-2 py-4 ps_div sec_bg"
            id="06"
          >
            <div className="dtl_sec_left d-flex flex-column w-md-50 w-100">
              <p className="sec_title">{obj?.Arr3Title}</p>
              <p className="sec_para">
                {showText2
                  ? obj.Arr3SubTitle.slice(0, 170).concat("... ")
                  : obj.Arr3SubTitle}
                <span onClick={toggleShowText2} className="text_span">
                  {showText2 && "Read more"}
                </span>
              </p>

              <ul className="sec_list">
                {obj?.listView3 &&
                  obj?.Array3.map((item, i) => (
                    <li key={i} className="sec_list_item">
                      {item.Title}
                    </li>
                  ))}
              </ul>
              <a href={`tel:${obj.buttonPhoneNumber}`}>
                <button
                  className="consult_btn_mob"
                  onClick={(e) =>
                    fbPixelContact(obj.buttonPhoneNumber, "Consultation")
                  }
                >
                  Call Now For Consultation
                </button>
              </a>
            </div>

            <div className="dtl_sec_right w-md-50 w-100">
              <LazyLoadImage
                delayTime={500}
                src={obj?.Detailimage3}
                alt="source_img"
                className="dtl_sec2_img"
              />
            </div>
          </div>
        )}

        <div className="last_dtl_sec_mob last_div_care" id="07">
          <div className="gray_img d-flex flex-column-reverse gap-2 py-4 ">
            <div className="dtl_sec_left d-flex flex-column w-md-50 w-100">
              <p className="sec_title txt_clr_yellow last_care_para1">
                {obj.lastTitle}
              </p>
              <p className="sec_para txt_clr_white last_care_para">
                {obj.lastPara}
              </p>
              <ul className="floating_bullets">
                {obj.lastBulletArray.map((item, i) => (
                  <li key={i} className="sec_list_item txt_clr_white">
                    <LazyLoadImage
                      delayTime={500}
                      style={{
                        width: "12px",
                        height: "9px",
                        marginRight: "13px",
                      }}
                      alt="pointer"
                      src={iconMarker}
                    />
                    <span> {item}</span>
                  </li>
                ))}
              </ul>
              <a href={`tel:${obj.buttonPhoneNumber}`}>
                <button
                  className="consult_btn_mob"
                  onClick={(e) =>
                    fbPixelContact(obj.buttonPhoneNumber, "Consultation")
                  }
                >
                  Call Now For Consultation
                </button>
              </a>
            </div>

            <div className="dtl_sec_right w-md-50 w-100">
              <LazyLoadImage
                delayTime={500}
                src={obj.floatingImg}
                alt="source_img"
                className="dtl_sec_img img-sm-fluid"
              />
            </div>
          </div>
        </div>

        <div className="spaceing"></div>
      </div>
      <Faq data={obj} />
    </>
  );
};

export default withRouter(ServiceDetailPageContent);
