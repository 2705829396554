export const BannerData = {
  "/about": {
    Title: "About Us",
    SubTitle: "Premier Laparoscopic & Vascular Surgery Clinic",
    BannerImage: "about_head",
    BannerTitle: "about_heading",
    BannerSubTitle: "about_para",
  },
  "/services": {
    Title: "Our Services",
    SubTitle: `We are the providers of the <span class="yellowText">BEST Advanced Laparoscopic Surgical</span> services in <span class="yellowText">Pakistan.</span>`,
    BannerImage: "services_head",
    BannerTitle: "about_heading",
    BannerSubTitle: "about_para",
  },
  "/contact-us": {
    Title: "Contact Us",
    SubTitle:
      "If you have any queries, please fill out the form below. We'll get back to you shortly!",
    BannerImage: "contact_us_head",
    BannerTitle: "about_heading",
    BannerSubTitle: "about_para",
  },
  "/faq": {
    Title: "FAQ’s",
    SubTitle:
      "Learn the answers to frequently asked questions regarding surgery.",
    BannerImage: "faq_head_banner",
    BannerTitle: "about_heading",
    BannerSubTitle: "about_para",
  },
  "/termofuse": {
    Title: "Terms of Use",
    SubTitle: "Information on the website is governed by these offered terms. ",
    BannerImage: "termofuse_head_banner",
    BannerTitle: "about_heading",
    BannerSubTitle: "about_para",
  },
  "/privacypolicy": {
    Title: "Privacy Policy",
    SubTitle:
      "We have measures in place to maintain the privacy of website users.",
    BannerImage: "privacypolicy_header_banner",
    BannerTitle: "about_heading",
    BannerSubTitle: "about_para",
  },
  "/blog": {
    Title: "Blogs",
    SubTitle:
      "Updates about surgical treatments that improve health and quality of life.",
    BannerImage: "blog_head_banner",
    BannerTitle: "about_heading",
    BannerSubTitle: "about_para",
  },
  "/blogdetail/1": {
    Title: "Blogs",
    SubTitle:
      "Updates about surgical treatments that improve health and quality of life.",
    BannerImage: "about_head_blogdetail",
    BannerTitle: "about_heading",
    BannerSubTitle: "about_para",
  },
  "/blogdetail/2": {
    Title: "Blogs",
    SubTitle:
      "Updates about surgical treatments that improve health and quality of life.",
    BannerImage: "about_head_blogdetail",
    BannerTitle: "about_heading",
    BannerSubTitle: "about_para",
  },
  "/blogdetail/3": {
    Title: "Blogs",
    SubTitle:
      "Updates about surgical treatments that improve health and quality of life.",
    BannerImage: "about_head_blogdetail",
    BannerTitle: "about_heading",
    BannerSubTitle: "about_para",
  },
  "/blogdetail/4": {
    Title: "Blogs",
    SubTitle:
      "Updates about surgical treatments that improve health and quality of life.",
    BannerImage: "about_head_blogdetail",
    BannerTitle: "about_heading",
    BannerSubTitle: "about_para",
  },
  "/blogdetail/5": {
    Title: "Blogs",
    SubTitle:
      "Updates about surgical treatments that improve health and quality of life.",
    BannerImage: "about_head_blogdetail",
    BannerTitle: "about_heading",
    BannerSubTitle: "about_para",
  },
  "/blogdetail/6": {
    Title: "Blogs",
    SubTitle:
      "Updates about surgical treatments that improve health and quality of life.",
    BannerImage: "about_head_blogdetail",
    BannerTitle: "about_heading",
    BannerSubTitle: "about_para",
  },
  "/blogdetail/7": {
    Title: "Blogs",
    SubTitle:
      "Updates about surgical treatments that improve health and quality of life.",
    BannerImage: "about_head_blogdetail",
    BannerTitle: "about_heading",
    BannerSubTitle: "about_para",
  },
  "/blogdetail/8": {
    Title: "Blogs",
    SubTitle:
      "Updates about surgical treatments that improve health and quality of life.",
    BannerImage: "about_head_blogdetail",
    BannerTitle: "about_heading",
    BannerSubTitle: "about_para",
  },
  "/blogdetail/9": {
    Title: "Blogs",
    SubTitle:
      "Updates about surgical treatments that improve health and quality of life.",
    BannerImage: "about_head_blogdetail",
    BannerTitle: "about_heading",
    BannerSubTitle: "about_para",
  },
  "/blogdetail/10": {
    Title: "Blogs",
    SubTitle:
      "Updates about surgical treatments that improve health and quality of life.",
    BannerImage: "about_head_blogdetail",
    BannerTitle: "about_heading",
    BannerSubTitle: "about_para",
  },
  "/blogdetail/11": {
    Title: "Blogs",
    SubTitle:
      "Updates about surgical treatments that improve health and quality of life.",
    BannerImage: "about_head_blogdetail",
    BannerTitle: "about_heading",
    BannerSubTitle: "about_para",
  },
  "/blogdetail/12": {
    Title: "Blogs",
    SubTitle:
      "Updates about surgical treatments that improve health and quality of life.",
    BannerImage: "about_head_blogdetail",
    BannerTitle: "about_heading",
    BannerSubTitle: "about_para",
  },
  "/services-detail/laparoscopic": {
    Title: `Advance Laparoscopic Surgery`,
    SubTitle: `Premier laparoscopic healthcare facility <span class="yellowText">providing comprehensive surgical treatment.</span>`,
    BannerImage: "LaparoscopicBannerImage",
    BannerTitle: "about_heading",
    BannerSubTitle: "about_para",
  },
  "/services-detail/bariatric": {
    Title: `Bariatric/Obesity Clinic <span class="bariatric_title_red"> (Weight Loss)</span>`,
    SubTitle: `Surgical/ Endoscopic care specifically for obese/overweight population who <span class="yellowText">want to live a healthy life.</span>`,
    BannerImage: "BariatricBannerImage",
    BannerTitle: "about_heading",
    BannerSubTitle: "about_para",
  },
  "/services-detail/endocrine": {
    Title: "Endocrine Surgery",
    SubTitle: `Exceptional surgical care for early <span class="yellowText">diagnosis and treatment of endocrine disorders.</span>`,
    BannerImage: "EndocrineBannerImage",
    BannerTitle: "about_heading",
    BannerSubTitle: "about_para",
  },
  "/services-detail/cancercare": {
    Title: "Cancer Care Clinic",
    SubTitle: `Comprehensive cancer care for a complete cure that enables <span class="yellowText">oncological (cancer) patients to live normal lives.</span>`,
    BannerImage: "OncologicalBannerImage",
    BannerTitle: "about_heading",
    BannerSubTitle: "about_para",
  },
  "/services-detail/vascular": {
    CallAt: `<span class="yellowText">Call at</span>  <span class="bariatric_title_red">03259400555<span class="yellowText">for <span class="bariatric_title_red">24/7 Vascular Emergencies</span></span> </span><span class="yellowText1">(Acute Limb Ischemia Requiring Immediate Surgical Intervention)</span>`,
    Title: "Peripheral Vascular Clinic",
    SubTitle: `Our peripheral vascular surgeons and interventional radiologists specialize in treating <span class="yellowText">various vascular diseases.</span>`,
    BannerImage: "VascularBannerImage",
    BannerTitle: "about_heading",
    BannerSubTitle: "about_para",
  },
  "/services-detail/healthcare": {
    Title: `Women’s Healthcare Via<span class="detail_red"> Lady Surgeons </span> `,
    SubTitle: `Leading providers of minimally-invasive/breast-conservative surgeries that treat<span class="yellowText"> women’s health problems.</span>`,
    BannerImage: "HealthcareBannerImage",
    BannerTitle: "about_heading",
    BannerSubTitle: "about_para",
  },
};
