import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import profAsim from "../Assets/images/services/surgeonImages/prof_surgeon_asim_malik_4x.webp";
import drHisham from "../Assets/images/services/surgeonImages/dr_hisham_ahmed_4x.webp";
import drIram from "../Assets/images/services/surgeonImages/dr_iram_naseem_4x.webp";
import drOsama from "../Assets/images/services/surgeonImages/dr_osama_ather_4x.webp";
import drSadia from "../Assets/images/services/surgeonImages/dr_sadia_4x.webp";
import drSaima from "../Assets/images/services/surgeonImages/dr_saima_amjad_4x.webp";
import team from "../Assets/images/team.webp";
import "../AboutUS/progress.css";
import MultiCarousel from "./MultiCarousel";
import { LazyLoadImage } from "react-lazy-load-image-component";
const ProfileCarousal = () => {
  const [index, setIndex] = useState(0);
  const [isDesktop, setIsDesktop] = useState(
    window.innerWidth > 767 ? true : false
  );
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const updateDimensions = () => {
    setIsDesktop(window.innerWidth > 767 ? true : false);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [isDesktop]);

  return (
    <>
      <div className="testimonial_destop">
        <h1 className="testi_heading text-center"> Meet Our Surgeons </h1>
        {isDesktop ? (
          <MultiCarousel />
        ) : (
          <div className="surgeons_carousel">
            <Carousel
              interval={3000}
              activeIndex={index}
              onSelect={handleSelect}
              className="surgeons_card"
            >
              <Carousel.Item>
                <LazyLoadImage
                  delayTime={500}
                  className="meet_surgeons_img"
                  src={profAsim}
                />
                <div className="surgeons_info_div">
                  <p className="surgeons_name">Prof. Surgeon Asim Malik</p>
                  <p className="surgeons_specialization">Chief of Surgery</p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <LazyLoadImage
                  delayTime={500}
                  className="meet_surgeons_img"
                  src={drOsama}
                />
                <div className="surgeons_info_div">
                  <p className="surgeons_name">Dr. Osama Ather</p>
                  <p className="surgeons_specialization">
                    Incharge Advance Laparoscopy & Oncology Surgery
                  </p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <LazyLoadImage
                  delayTime={500}
                  className="meet_surgeons_img"
                  src={drIram}
                />
                <div className="surgeons_info_div">
                  <p className="surgeons_name">Dr. Iram Naseem</p>
                  <p className="surgeons_specialization">
                    Incharge Well Women Clinic & Gynaecology
                  </p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <LazyLoadImage
                  delayTime={500}
                  className="meet_surgeons_img"
                  src={drSaima}
                />
                <div className="surgeons_info_div">
                  <p className="surgeons_name">Dr. Saima Amjad</p>
                  <p className="surgeons_specialization">
                    Incharge Bariatric Weight Loss & Endocrine Surgery
                  </p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <LazyLoadImage
                  delayTime={500}
                  className="meet_surgeons_img"
                  src={drHisham}
                />
                <div className="surgeons_info_div">
                  <p className="surgeons_name">Dr. Hisham Ahmed</p>
                  <p className="surgeons_specialization">
                    Incharge Vascular & Trauma Services
                  </p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <LazyLoadImage
                  delayTime={500}
                  className="meet_surgeons_img"
                  src={team}
                />
                <div className="surgeons_info_div">
                  <p className="surgeons_name">Dr. Tasadduq Hussain </p>
                  <p className="surgeons_specialization">Emergency Responder</p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <LazyLoadImage
                  delayTime={500}
                  className="meet_surgeons_img"
                  src={drSadia}
                />
                <div className="surgeons_info_div">
                  <p className="surgeons_name">Dr. Sadia</p>
                  <p className="surgeons_specialization">Oncology Surgeon</p>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        )}
      </div>
    </>
  );
};

export default ProfileCarousal;
