import TextField from "@mui/material/TextField";
import React from "react";

const InputField = ({
  label,
  name,
  onChange,
  rows,
  onBlur,
  value,
  onKeyPress,
  onFocus,
  autoFocus,
  type,
  onClick,
  height,
}) => {
  const style = {
    "& .MuiOutlinedInput": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400 !important",
      fontSize: "14px !important",
      lineHeight: "16px !important",
      color: "#616161",
    },

    "& .MuiInputLabel-root": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400 !important",
      fontSize: "14px !important",
      lineHeight: "16px !important",
      color: "#616161",
      "&.Mui-focused ": {
        color: "#3C3C3C",
      },
    },
    "& .MuiOutlinedInput-root": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400 !important",
      fontSize: "14px !important",
      lineHeight: "16px !important",
      color: "#616161",
      padding: "13px 15px !important",
      borderRadius: "5px !important",
      marginBottom: "17px",
      "& .MuiInputBase-input": {
        fontSize: "16px",
        // height: "16px",
        marginTop: "3px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3C3C3C",
      },
      "& fieldset": {
        borderColor: "1px solid #616161 !important",
        // height: "52px",
      },
    },
  };
  return (
    <TextField
      sx={style}
      id="outlined-basic"
      label={label}
      variant="outlined"
      name={name}
      onChange={onChange}
      fullWidth
      multiline
      rows={rows}
      onBlur={onBlur}
      value={value}
      onKeyPress={onKeyPress}
      onFocus={onFocus}
      autoFocus={autoFocus}
      type={type ? type : "text"}
      inputProps={{
        style: {
          height: height,
        },
      }}
      onClick={onClick}
    />
  );
};

export default InputField;
