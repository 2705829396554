import React, { Component } from "react";
import "./Instagram.css";
import "react-ig-feed/dist/index.css";

class FeedInside extends Component {
  render() {
    return <></>;
  }
}

export default FeedInside;
