import React, { useEffect, useState } from "react";
import "../Testominal/Insta.css";
import insta1 from "../Assets/images/services/instagram_1_2x.webp";
import insta2 from "../Assets/images/services/instagram_2_2x.webp";
import insta3 from "../Assets/images/services/instagram_3_2x.webp";
import insta4 from "../Assets/images/services/instagram_4_2x.webp";
import insta5 from "../Assets/images/services/instagram_5_2x.webp";
import insta6 from "../Assets/images/services/instagram_6_2x.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import InstagramFeed from "react-ig-feed";
import "react-ig-feed/dist/index.css";

const Insta = () => {
  const [tokenExpired, setTokenExpired] = useState(false);
  useEffect(() => {
    const checkTokenExpiration = () => {
      const generationDate = new Date("2023-08-11"); // Set the token generation date
      generationDate.setDate(generationDate.getDate() + 20); // Add 20 days to the generation date

      const currentDate = new Date();

      return currentDate > generationDate;
    };

    const isTokenExpired = checkTokenExpiration();

    setTokenExpired(isTokenExpired);
  }, []);
  return (
    <>
    {tokenExpired ? (
      // <p>Error: Token has expired.</p>
      <></>
    ) : (
    <>
      <div className="Insta_main_div bg-white  content_div insta_div_new">
        <div className="Insta_heading_div ">
          <p className="Insta_letter ">Follow Us On Instagram</p>
        </div>
        <div className="Insta_imgs_div d-flex ">
          {/* <LazyLoadImage
            delayTime={500}
            src={insta1}
            alt="follow us on insta"
            className="insta_img img-fluid"
          />
          <LazyLoadImage
            delayTime={500}
            src={insta2}
            alt="follow us on insta"
            className="insta_img img-fluid"
          />
          <LazyLoadImage
            delayTime={500}
            src={insta3}
            alt="follow us on insta"
            className="insta_img img-fluid"
          /> */}
            <InstagramFeed
                token="IGQVJWOUJxRzYzeElRa21QbW4tWjMzSGphczV6SWM0dU13Q01hd2I5cTlZAN0hOcGRVd18tUG43YTlDWU8yMWZAWRGdpaUltdG4ybjQ1a2RXX09QcWdVaGJDU25rWmdRT0wybEkyMC10MEdKTjBkbHRjdgZDZD"
                counter="3"
              />
        </div>
        <div className="Insta_imgs_mob justify-content-center gap-2 gap-sm-3 d-sm-none d-flex">
          <LazyLoadImage
            delayTime={500}
            src={insta4}
            alt="follow us on insta"
            className="insta_mob"
          />
          <LazyLoadImage
            delayTime={500}
            src={insta5}
            alt="follow us on insta"
            className="insta_mob"
          />
          <LazyLoadImage
            delayTime={500}
            src={insta6}
            alt="follow us on insta"
            className="insta_mob"
          />
        </div>
      </div>
    </>
    )}
    </>
  );
};

export default Insta;
