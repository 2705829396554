import React, { useState, useEffect } from "react";
import "../Footer/footer.css";
import { Link, useLocation } from "react-router-dom";
import footerLogo1 from "../Assets/images/advance_logo_2x.webp";
import footerLogo2 from "../Assets/images/imc_logo_2x.webp";
import footerLogo2Replace from "../Assets/images/services/imc_logo_2_2x.webp";
import footerLogo3 from "../Assets/images/services/genesis_logo_2x.webp";
import footerVector from "../Assets/images/services/footerImages/chevron_down_2x.webp";
import clrSocial1 from "../Assets/images/services/meta_logo_2x.webp";
import clrSocial2 from "../Assets/images/services/insta_logo_2x.webp";
import clrSocial3 from "../Assets/images/services/linkedin_logo_2x.webp";
import clrSocial4 from "../Assets/images/services/footerImages/Twitter.png";
import footerPhoneIcon from "../Assets/images/services/footerImages/phone_2x.webp";
import footerMailIcon from "../Assets/images/services/footerImages/mail_2x.webp";
import footerMapIcon from "../Assets/images/services/footerImages/map_pin_2x.webp";
import UnderlineImg from "../Assets/images/services/footerImages/Line82.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import TagManager from "react-gtm-module";
import { color } from "@mui/system";

const Footer = () => {
  const [vectorImg, setVectorImg] = useState(false);
  const [isDesktop, setIsDesktop] = useState(
    window.innerWidth >= 768 ? true : false
  );
  const location = useLocation();

  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-T8F8X7H" });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [isDesktop]);

  const updateDimensions = () => {
    setIsDesktop(window.innerWidth >= 768 ? true : false);
  };

  const toggleVectorImg = () => {
    setVectorImg(!vectorImg);
  };

  const openMap = (a) => {
    if (a === 1) {
      window.open("https://goo.gl/maps/qQnetCzocH7NUyqM7");
    } else {
      window.open(
        "https://maps.google.com?q=96+Shadman,+Jail+Road+opp.+Lahore+College+for+Women,+Shadman+1+Shadman,+Lahore,+Punjab+54000"
      );
    }
  };
  const openFacebook = () => {
    window.open("https://www.facebook.com/adv.surgicare/");
  };
  const openLinkedin = () => {
    window.open("https://www.linkedin.com/AdvanceSurgicare");
  };
  const openInsta = () => {
    window.open("https://www.instagram.com/adv.surgicare/");
  };
  const openTwitter = () => {
    window.open("https://twitter.com/advncesurgicare");
  };
  const openXcentric = () => {
    window.open("https://www.xcentricservices.com/");
  };
  return (
    <>
      <div className="advance_footer_div">
        <footer className="advance_footer">
          <div className="footer_sec1">
            <div className="footer_logo1_div">
              {" "}
              <Link to="/">
                <LazyLoadImage
                  delayTime={500}
                  src={footerLogo1}
                  alt="footer_logo"
                  className="footer_logo1_img"
                />
              </Link>
            </div>
            <div className="footer_para_div">
              <p className="footer_para">
                Advance Surgicare is a facility established to provide
                specialized yet minimally-invasive surgical care to patients in
                Pakistan.{" "}
              </p>
            </div>
            <div className="footer_ShowOnMap_div d-flex flex-column">
              <a
                className="footer_anchor showOnMap_anchor"
                href="https://goo.gl/maps/qQnetCzocH7NUyqM7"
                target="_blank"
              >
                Show on Map
              </a>
              <LazyLoadImage
                delayTime={500}
                className="underline_img"
                alt="underline"
                src={UnderlineImg}
              />
            </div>
          </div>
          <div className="footer_sec2">
            {/* <div className="footer_logo2_div">
              <Link to="/">
                <LazyLoadImage
                  delayTime={500}
                  src={isDesktop ? footerLogo2 : footerLogo2Replace}
                  alt="footer_logo"
                  className="footer_logo2_img"
                />
              </Link>
            </div> */}
            <div className="footer_contact_div d-flex">
              <div className="footer_icon_div d-flex align-items-center justify-content-center">
                <LazyLoadImage
                  delayTime={500}
                  className="footer_icon_img"
                  alt="footer_icon"
                  src={footerPhoneIcon}
                />
              </div>
              <div className="footer_contactnum_div d-flex flex-column justify-content-between">
                <a className="footer_anchor" href="tel:+923259400555" style={{fontSize:'20px'}}>
                  +92 325 9400555
                </a>
                {/* <a className="footer_anchor" href="tel:+923269400555">
                  +92 326 9400555
                </a> */}
              </div>
            </div>
            {/* <div className="footer_Map_div d-flex">
              <div className="footer_icon_div d-flex align-items-center justify-content-center">
                <LazyLoadImage
                  delayTime={500}
                  className="footer_icon_img"
                  alt="footer_icon"
                  src={footerMapIcon}
                />
              </div>
              <div className="footer_address_div">
                <p
                  className="footer_anchor"
                  onClick={(e) => openMap(1)}
                  style={{ cursor: "pointer" }}
                >
                  153/1, F-Block, Phase-5 DHA Lahore – Pakistan
                </p>
              </div>
            </div> */}
            <div className="footer_mail_div d-flex">
              <div className="footer_icon_div d-flex align-items-center justify-content-center">
                <LazyLoadImage
                  delayTime={500}
                  className="footer_icon_img"
                  alt="footer_icon"
                  src={footerMailIcon}
                />
              </div>
              <div className="footer_mailTo_div d-md-flex ">
                <a
                  className="footer_anchor last_screen_mail_anchor align-self-center"
                  href="#"
                >
                  queries@advancesurgicare.com<br />
                  appointment@advancesurgicare.com
                </a>
              </div>
            </div>
          </div>
          <div className="footer_sec3">
            <div className="footer_logo3_div">
              {" "}
              {/* <Link to="/">
                <LazyLoadImage
                  delayTime={500}
                  src={footerLogo3}
                  alt="footer_logo"
                  className="footer_logo3_img "
                />
              </Link> */}
            </div>
            <div
              className="footer_contact_div d-flex"
              style={{ height: "31px" }}
            >
              <div className="footer_icon_div d-flex align-items-center justify-content-center">
                <LazyLoadImage
                  delayTime={500}
                  className="footer_icon_img"
                  alt="footer_icon"
                  src={footerPhoneIcon}
                />
              </div>
              <div className="footer_contactnum_div d-flex align-items-center">
                <a className="footer_anchor" href="tel: +923269400555" style={{fontSize:'20px', color:'#dd0b09 !important'}}>
                  +92 326 9400555

                </a>
              </div>
            </div>
            {/* <div className="footer_Map_div d-flex">
              <div className="footer_icon_div d-flex align-items-center justify-content-center specific_height">
                <LazyLoadImage
                  delayTime={500}
                  className="footer_icon_img"
                  alt="footer_icon"
                  src={footerMapIcon}
                />
              </div>
              <div className="footer_address_div ">
                <p
                  className="footer_anchor"
                  onClick={(e) => openMap(2)}
                  style={{ cursor: "pointer" }}
                >
                  96 Shadman, Jail Road opp. LCW Shadman 1, Lahore.
                </p>
              </div>
            </div> */}
          </div>

          <div className="footer_sec4">
            <div className="footer_otherInfo_div d-flex justify-content-between align-items-baseline">
              <p className="otherInfo_text" onClick={toggleVectorImg}>
                Emergency Contact
              </p>{" "}
              <div className="footer_vector_div">
                <LazyLoadImage
                  delayTime={500}
                  src={footerVector}
                  alt="vactor"
                  className={
                    vectorImg ? "toggle_vector_img" : "footer_vector_img"
                  }
                  onClick={toggleVectorImg}
                />
              </div>
            </div>
            <div
              className={`d-flex flex-column ${vectorImg ? "HPT_anchor_div" : "hide_HPT"
                }`}
            >
              <Link
                to="/faq"
                className={
                  location.pathname === "/faq"
                    ? isDesktop
                      ? "footer_anchor_web"
                      : "hpt_anchor_active hpt_anchor"
                    : "footer_anchor hpt_anchor"
                }
              >
                Help and FAQs
              </Link>
              <Link
                to="/privacypolicy"
                className={
                  location.pathname === "/privacypolicy"
                    ? isDesktop
                      ? "footer_anchor_web"
                      : "hpt_anchor_active hpt_anchor"
                    : "footer_anchor hpt_anchor"
                }
              >
                Privacy Policy
              </Link>
              <Link
                to="/termofuse"
                className={
                  location.pathname === "/termofuse"
                    ? isDesktop
                      ? "footer_anchor_web"
                      : "hpt_anchor_active hpt_anchor"
                    : "footer_anchor hpt_anchor"
                }
              >
                Terms of Use
              </Link>
            </div>
            <div className="footer_metaTags_div d-flex">
              <LazyLoadImage
                delayTime={500}
                className="footer_meta_imgs"
                alt="meta"
                src={clrSocial1}
                onClick={(e) => openFacebook()}
              />
              <LazyLoadImage
                delayTime={500}
                className="footer_meta_imgs"
                alt="meta"
                src={clrSocial2}
                onClick={(e) => openInsta()}
              />
              <LazyLoadImage
                delayTime={500}
                className="footer_meta_imgs"
                alt="meta"
                src={clrSocial3}
              // onClick={(e) => openLinkedin()}
              />
              <LazyLoadImage
                delayTime={500}
                className="footer_meta_imgs"
                alt="meta"
                src={clrSocial4}
                onClick={(e) => openTwitter()}
              />
            </div>
          </div>
          <div className="footer_sec5">
            <div className="footer_ShowOnMap_mob_div d-flex flex-column">
              <a
                className="footer_anchor showOnMap_anchor"
                href="https://goo.gl/maps/qQnetCzocH7NUyqM7"
                target="_blank"
              >
                Show on Map
              </a>
              <LazyLoadImage
                delayTime={500}
                className="underline_img"
                alt="underline"
                src={UnderlineImg}
              />
            </div>
            <div className="footer_metaTags_mob_div d-flex">
              <LazyLoadImage
                delayTime={500}
                className="footer_meta_imgs"
                alt="meta"
                src={clrSocial1}
                onClick={(e) => openFacebook()}
              />
              <LazyLoadImage
                delayTime={500}
                className="footer_meta_imgs"
                alt="meta"
                src={clrSocial2}
                onClick={(e) => openInsta()}
              />{" "}
              <LazyLoadImage
                delayTime={500}
                className="footer_meta_imgs"
                alt="meta"
                src={clrSocial3}
              // onClick={(e) => openLinkedin()}
              />
              <LazyLoadImage
                delayTime={500}
                className="footer_meta_imgs"
                alt="meta"
                src={clrSocial4}
                onClick={(e) => openTwitter()}
              />
            </div>
          </div>
        </footer>
        <footer className="footer_bottom_web d-flex justify-content-center align-items-center">
          <p className="footer_bottom_para_web d-flex  align-items-center">
            <span className="footer_yellow_font">Advance Surgicare </span>
            {""} &#169; <b>2024</b> | Designed, Developed &#38; Marketed By{" "}
            {/* <strong
              className="footer_blue_text_web"
              style={{ cursor: "pointer" }}
              onClick={openXcentric}
            >
              Xcentric Services
            </strong> */}
            <a
                   className="footer_blue_text_web"
                   style={{ cursor: "pointer" }}
                href="https://www.xcentricservices.com/"
                target="_blank"
              >Xcentric Services</a>{" "}
          </p>
        </footer>
        <footer className="footer_bottom_mob d-flex justify-content-center align-items-center">
          <p className="footer_bottom_para_mob d-flex  align-items-center">
            Designed, Developed &#38; Marketed By{" "}
            {/* <strong
              className="footer_blue_text_mob"
              style={{ cursor: "pointer" }}
              onClick={openXcentric}
            >
              Xcentric Services
            </strong>{" "} */} 
             <a
                className="footer_blue_text_mob"
                style={{ cursor: "pointer" }}
                href="https://www.xcentricservices.com/"
                target="_blank"
              >Xcentric Services</a>{" "}
            &#169; <b>2024</b>
          </p>
        </footer>
      </div>
    </>
  );
};

export default Footer;
