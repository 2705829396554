import React from "react";
import AboutHead from "../AboutUS/AboutHead";
import AboutProgress from "../AboutUS/AboutProgress";
import ProfileCarousal from "../AboutUS/ProfileCarousal";
import Profile from "../Profile/Profile";

const AboutPage = () => {
  return (
    <>
      <AboutHead />
      <AboutProgress />
      <Profile />
      <ProfileCarousal />
    </>
  );
};

export default AboutPage;
