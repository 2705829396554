import React from "react";
import { useLocation } from "react-router-dom";
import SearchResult from "../SearchResult/SearchResult";
const HeaderBanner = ({ data }) => {
  const location = useLocation().pathname;
  const obj = data[location];
  return (
    <>
      {location === "/search-result" ? (
        <SearchResult />
      ) : (
        <div className={obj?.BannerImage}>
          <div className="header_banner_all">
            <h1
              className={obj?.BannerTitle}
              dangerouslySetInnerHTML={{ __html: obj?.Title }}
            />
            <p
              className={obj?.BannerSubTitle}
              dangerouslySetInnerHTML={{ __html: obj?.SubTitle }}
            />
            {location === "/services-detail/vascular" && (
              <p
                className={obj?.BannerSubTitle}
                dangerouslySetInnerHTML={{ __html: obj?.CallAt }}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default HeaderBanner;
