import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ChangingProgressProvider from "./ChangingProgressProvider";
// Import react-circular-progressbar module and styles

const progress1 = () => {
  return (
    <ChangingProgressProvider values={[0, 20, 40, 60, 90, 90]}>
      {(percentage) => (
        <CircularProgressbar
          strokeWidth={3}
          value={percentage}
          text={`${percentage}%`}
        />
      )}
    </ChangingProgressProvider>
  );
};

export default progress1;
