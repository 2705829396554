import "../Appointment/appointment.css";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import useValidationFunctions from "../Utils/ValidationFunctions";
import InputField from "../Input/InputField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { SurgicalType } from "../Data/SurgicalType";
import { useLocation } from "react-router-dom";
import { servicesDataArray } from "../Data/DetailPageGenericArray.js";
import DatePicker from "react-mobile-datepicker";
import Modal from "react-bootstrap/Modal";
import { dateConfig } from "../Data/dateConfig";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReactPixel from "react-facebook-pixel";

export const styles = {
  select: {
    ".MuiOutlinedInput-notchedOutline": {
      borderRadius: "5px",
    },

    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: " #3C3C3C !important",
      color: "red",
    },
    ".MuiSvgIcon-root": {
      fill: "none !important",
      stroke: "#3A3A3A !important",
    },
    "MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl": {
      fontSize: "60px",
      color: "red",
    },
    "&.Mui-focused  .MuiSvgIcon-root ": {
      fill: "none !important",
      stroke: "#3C3C3C !important",
    },
    "MuiInputLabel-root": {
      fontSize: "20px !important",
    },
  },
  radio: {
    "&,&.Mui-focused": {
      color: "#000000",
    },
  },
};
export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const Appointment = ({ subservice }) => {
  const [isDesktop, setIsDesktop] = useState(
    window.innerWidth > 600 ? true : false
  );
  const [serviceExpanded, setServiceExpanded] = useState(false);
  const [surgeryTypeExpanded, setSurgeryTypeExpanded] = useState(false);
  const [genderExpanded, setGenderExpanded] = useState(false);
  const [genderLabel, setGenderLabel] = useState("Gender*");

  const updateDimensions = () => {
    setIsDesktop(window.innerWidth > 600 ? true : false);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [isDesktop]);
  const SubArray = [
    "Advance Laparoscopic Surgery",
    "Peripheral Vascular Clinic",
    "Endocrine Surgery",
    "Cancer Care Clinic",
    "Bariatric/Obesity Clinic (Weight Loss)",
    "Women’s Healthcare Clinic",
  ];
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [time, setTime] = useState(new Date());

  //Required Fields Errors States
  const [required, setRequired] = useState({
    serviceRequired: false,
    surgeryTypeRequired: false,
    dateRequired: false,
    genderRequired: false,
  });

  const handleCancel = () => {
    setIsOpen(false);
    handleClose();
  };

  const handleSelect = (time1) => {
    setIsOpen(false);
    setValues({ ...values, date: time1.toUTCString().slice(5, 17) });
    handleClose();
    setRequired({
      ...required,
      dateRequired: false,
    });
  };
  const [show, setShow] = useState(false);
  const [emailShow, setEmailShow] = useState(false);
  const [emailFailed, setEmailFailed] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { errors, setErrors, validateEmail, validatePhone, checkNumbers } =
    useValidationFunctions();
  const surgeryType = Object.keys(SurgicalType).filter(
    (item) =>
      item
        .toString()
        .toLowerCase()
        .includes(location.pathname.toString().toLowerCase().substr(17)) && item
  );
  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    services: "",
    surgery_type: "",
    gender: "",
    age: "",
    message: "",
    scrollDate: time,
    date: {},
  });
  const [selectService, setSelectService] = useState("Services*");
  const [surgeryTypeState, setSurgeryTypeState] = useState("Surgery Type");
  const changeTitle = (item, index) => {
    setSelectService(SubArray.filter((item, index2) => index2 === index));
    setServiceExpanded(false);
    setValues({
      ...values,
      services: item,
    });
    setRequired({
      ...required,
      serviceRequired: false,
    });
  };

  const changeSurgeryTypeTitle = (item, index) => {
    setSurgeryTypeState(
      SurgicalType[surgeryType.toString()].filter(
        (item, index2) => index2 === index
      )
    );
    setSurgeryTypeExpanded(false);
    setValues({
      ...values,
      surgery_type: item,
    });
    setRequired({
      ...required,
      surgeryTypeRequired: false,
    });
  };

  useEffect(() => {
    setValues({
      name: "",
      email: "",
      phone: "",
      services: "",
      surgery_type: "",
      gender: "",
      age: "",
      message: "",
      scrollDate: time,
      date: {},
    });
    setSurgeryTypeState("Surgery Type");
    setSelectService("Services*");
    setErrors({});
  }, [subservice, setErrors, location]);

  const handleFields = (e) => {
    const value = e.target.value;

    setValues({
      ...values,
      [e.target.name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let data = { ...values };
    if (location.pathname.toString().includes("services-detail")) {
      let randomAA = surgeryType.toString();
      if (!values.surgery_type) {
        setRequired({
          ...required,
          surgeryTypeRequired: true,
        });
        return;
      }
      if (Object.keys(values.date).length === 0) {
        setRequired({
          ...required,
          dateRequired: true,
        });
        return;
      }
      if (!values.gender) {
        setRequired({
          ...required,
          genderRequired: true,
        });
        return;
      }
      fetch(
        `https://bs6t5xwr5yr2qn63oewc4jjy2y0mudac.lambda-url.ap-southeast-1.on.aws?&name=${data.name}&email=${data.email}&phone=${data.phone}&service=${randomAA}&gender=${data.gender}&age=${data.age}&message=${data.message}&date=${data.date}&servicetype=${data.surgery_type}`
      )
        .then((response) => response.json())
        .then((data) => {
          // throw 400;
          setValues({
            name: "",
            email: "",
            phone: "",
            services: "",
            surgery_type: "",
            gender: "",
            age: "",
            message: "",
            scrollDate: time,
            date: {},
          });
          setSurgeryTypeState("Surgery Type");
          setEmailFailed(false);
          setEmailShow(true);
          setTimeout(() => {
            setEmailShow(false);
          }, 2000);
          ReactPixel.fbq("track", "Lead");
        })
        .catch((error) => {
          setEmailFailed(true);
          setEmailShow(true);
          setTimeout(() => {
            setEmailShow(false);
          }, 2000);
        });
    } else {
      if (!values.services) {
        setRequired({
          ...required,
          serviceRequired: true,
        });
        return;
      }
      if (Object.keys(values.date).length === 0) {
        setRequired({
          ...required,
          dateRequired: true,
        });
        return;
      }
      if (!values.gender) {
        setRequired({
          ...required,
          genderRequired: true,
        });
        return;
      }
      fetch(
        `https://bs6t5xwr5yr2qn63oewc4jjy2y0mudac.lambda-url.ap-southeast-1.on.aws?&name=${data.name}&email=${data.email}&phone=${data.phone}&service=${data.services}&gender=${data.gender}&age=${data.age}&message=${data.message}&date=${data.date}`
      )
        .then((response) => response.json())
        .then((data) => {
          setValues({
            name: "",
            email: "",
            phone: "",
            services: "",
            surgery_type: "",
            gender: "",
            age: "",
            message: "",
            scrollDate: time,
            date: {},
          });
          setSelectService("Services*");
          setEmailFailed(false);
          setEmailShow(true);
          setTimeout(() => {
            setEmailShow(false);
          }, 2000);
          ReactPixel.fbq("track", "Lead");
        })
        .catch((error) => {
          setEmailFailed(true);
          setEmailShow(true);
          setTimeout(() => {
            setEmailShow(false);
          }, 2000);
        });
    }
  };

  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  const futureMonths = months.filter((_, i) => i + 1 >= month);

  const [dayState, setDayState] = useState(date);
  const [monthState, setMonthState] = useState(month);
  const [yearState, setYearState] = useState(year);

  function handleCalenderDay(event) {
    setDayState(event.target.value);
  }
  function handleCalenderMonth(event) {
    setMonthState(event.target.value);
  }
  function handleCalenderYear(event) {
    setYearState(event.target.value);
  }

  function range(start, end) {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx);
  }
  const days = range(date, 31);

  return (
    <>
      <div className="appointment_mob" id="contactus" name="contactus">
        <p className="heading_new">Book An Appointment</p>

        <div className="appointment">
          <form className="form_main_div" onSubmit={(e) => handleSubmit(e)}>
            <Grid
              container
              spacing={isDesktop ? 2 : 0}
              sx={
                isDesktop
                  ? {
                      my: "3rem",
                      justifyContent: "center",
                    }
                  : { my: "1.5rem", justifyContent: "center" }
              }
            >
              {isDesktop ? (
                <>
                  <Grid
                    sx={{
                      margin: `${!isDesktop && "0 1.5rem"}`,
                    }}
                    item
                    sm={5}
                    xs={12}
                  >
                    <InputField
                      label="Name*"
                      name="name"
                      onChange={handleFields}
                      value={values.name}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={5}
                    sx={isDesktop ? {} : { mx: "1.5rem" }}
                  >
                    <InputField
                      label="Age*"
                      onKeyPress={values.age.length === 0 && checkNumbers}
                      name="age"
                      type="number"
                      onChange={handleFields}
                      value={values.age}
                    />
                    <div className="text-danger">{errors?.numbers}</div>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid
                    sx={isDesktop ? {} : { mx: "1.5rem" }}
                    item
                    sm={5}
                    xs={12}
                  >
                    <InputField
                      label="Name*"
                      name="name"
                      onChange={handleFields}
                      value={values.name}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={5}
                    sx={isDesktop ? {} : { mx: "1.5rem" }}
                  >
                    <InputField
                      label="Age*"
                      onKeyPress={checkNumbers}
                      name="age"
                      type="number"
                      onChange={handleFields}
                      value={values.age}
                    />
                    <div className="text-danger">{errors?.numbers}</div>
                  </Grid>
                </>
              )}

              <Grid sx={isDesktop ? {} : { mx: "1.5rem" }} item sm={5} xs={12}>
                <InputField
                  label="Phone Number*"
                  value={values.phone}
                  onKeyPress={validatePhone}
                  name="phone"
                  onChange={handleFields}
                />
                <div className="text-danger">{errors?.phone}</div>
              </Grid>

              <Grid sx={isDesktop ? {} : { mx: "1.5rem" }} item sm={5} xs={12}>
                <InputField
                  label="Email*"
                  onKeyPress={validateEmail}
                  name="email"
                  value={values.email}
                  onChange={handleFields}
                />
                <div className="text-danger">{errors?.email}</div>
              </Grid>
              {(
                isDesktop
                  ? values.name &&
                    values.age &&
                    values.phone &&
                    values.email &&
                    !errors.email &&
                    !errors.phone
                  : values.name &&
                    values.phone &&
                    values.email &&
                    !errors.email &&
                    !errors.phone
              ) ? (
                <>
                  <Grid
                    sx={isDesktop ? {} : { mx: "1.5rem" }}
                    sm={5}
                    item
                    xs={12}
                  >
                    {location.pathname
                      .toString()
                      .includes("services-detail") ? (
                      <InputField
                        label="Service*"
                        name="services"
                        value={surgeryType.toString()}
                        onChange={handleFields}
                      />
                    ) : (
                      <Accordion
                        expanded={serviceExpanded}
                        onClick={() => setServiceExpanded(!serviceExpanded)}
                        sx={{
                          // minHeight: "61px",
                          position: "relative",
                          backgroundColor: "#ffffff",
                          color: "#616161",
                          marginBottom: "17px",
                          border: "1px solid rgba(0, 0, 0, 0.23) !important",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon sx={{ fill: "#616161" }} />
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          sx={{
                            minHeight: "61px",
                            "& .Mui-expanded": {
                              margin: "0px",
                              // height: "50px !important",
                            },
                            "& .MuiTypography-root": {
                              fontSize: "16px",
                            },
                          }}
                        >
                          <Typography sx={{}}>{selectService}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            position: "absolute",
                            backgroundColor: "#ffffff",
                            color: "#616161",
                            zIndex: "99",
                            width: "100%",
                            padding: "0px",
                            borderRadius: "0px 0px 4px 4px",
                            boxShadow: "4px 4px 17px rgba(0, 0, 0, 0.06);",
                            "& .MuiTypography-root": {
                              cursor: "pointer",
                              padding: "10px 16px",
                              fontSize: "16px",
                              ":hover": {
                                backgroundColor: "#616161",
                                cursor: "pointer",
                                color: "#ffffff",
                              },
                            },
                          }}
                        >
                          {SubArray.map((item, index) => {
                            return (
                              <Typography
                                key={index}
                                onClick={() => changeTitle(item, index)}
                              >
                                {item}
                              </Typography>
                            );
                          })}
                        </AccordionDetails>
                      </Accordion>
                    )}
                    {required.serviceRequired && (
                      <div className="text-danger">Required</div>
                    )}
                  </Grid>

                  {location.pathname.toString().includes("services-detail") ? (
                    <Grid
                      sx={isDesktop ? {} : { mx: "1.5rem" }}
                      sm={5}
                      item
                      xs={12}
                    >
                      <Accordion
                        expanded={surgeryTypeExpanded}
                        onClick={() =>
                          setSurgeryTypeExpanded(!surgeryTypeExpanded)
                        }
                        sx={{
                          height: "61px",
                          position: "relative",
                          backgroundColor: "#ffffff",
                          color: "#616161",
                          marginBottom: "17px",
                          border: "1px solid rgba(0, 0, 0, 0.23) !important",
                          "& .MuiButtonBase-root": {
                            // height: "46px",
                          },
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon sx={{ fill: "#616161" }} />
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          sx={{
                            minHeight: "61px",
                            "& .Mui-expanded": {
                              margin: "0px",
                            },
                            "& .MuiTypography-root": {
                              fontSize: "16px",
                            },
                          }}
                        >
                          <Typography sx={{}}>{surgeryTypeState}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            position: "absolute",
                            backgroundColor: "#ffffff",
                            color: "#616161",
                            zIndex: "99",
                            width: "100%",
                            padding: "0px",
                            borderRadius: "0px 0px 4px 4px",
                            boxShadow: "4px 4px 17px rgba(0, 0, 0, 0.06);",
                            "& .MuiTypography-root": {
                              cursor: "pointer",
                              padding: "10px 16px",
                              fontSize: "16px",
                              ":hover": {
                                backgroundColor: "#616161",
                                cursor: "pointer",
                                color: "#ffffff",
                              },
                            },
                          }}
                        >
                          {SurgicalType[surgeryType.toString()].map(
                            (item, index) => {
                              return (
                                <Typography
                                  key={index}
                                  onClick={(e) =>
                                    changeSurgeryTypeTitle(item, index)
                                  }
                                >
                                  {item}
                                </Typography>
                              );
                            }
                          )}
                        </AccordionDetails>
                      </Accordion>
                      {required.surgeryTypeRequired && (
                        <div className="text-danger">Required</div>
                      )}
                    </Grid>
                  ) : (
                    ""
                  )}

                  <Grid
                    sm={5}
                    item
                    xs={12}
                    sx={isDesktop ? {} : { mx: "1.5rem" }}
                  >
                    <InputField
                      label="Appointment Date*"
                      name="scrollDate"
                      type="date"
                      onClick={handleShow}
                      value={
                        Object.keys(values.date).length === 0 ? "" : values.date
                      }
                    />
                    {required.dateRequired && (
                      <div className="text-danger">Required</div>
                    )}
                  </Grid>

                  <Grid
                    sx={isDesktop ? {} : { mx: "1.5rem" }}
                    sm={5}
                    item
                    xs={12}
                  >
                    <Accordion
                      expanded={genderExpanded}
                      onClick={() => setGenderExpanded(!genderExpanded)}
                      sx={{
                        height: "61px",
                        position: "relative",
                        backgroundColor: "#ffffff",
                        color: "#616161",
                        marginBottom: "17px",
                        border: "1px solid rgba(0, 0, 0, 0.23) !important",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ fill: "#616161" }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          minHeight: "61px",
                          "& .Mui-expanded": {
                            margin: "0px",
                          },
                          "& .MuiTypography-root": {
                            fontSize: "16px",
                          },
                        }}
                      >
                        <Typography>{genderLabel}</Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          position: "absolute",
                          backgroundColor: "#ffffff",
                          color: "#616161",
                          zIndex: "9",
                          width: "100%",
                          padding: "0px",
                          borderRadius: "0px 0px 4px 4px",
                          boxShadow: "4px 4px 17px rgba(0, 0, 0, 0.06);",
                          "& .MuiTypography-root": {
                            cursor: "pointer",
                            padding: "10px 16px",
                            fontSize: "16px",
                            ":hover": {},
                          },
                        }}
                      >
                        <Typography>
                          <RadioGroup
                            row
                            sx={{
                              padding: "7px 0px",
                            }}
                            aria-labelledby="gender"
                            name="gender"
                            value={values.gender}
                            onChange={handleFields}
                          >
                            <FormControlLabel
                              sx={{
                                width: "40%",
                                margin: "0px",
                                "& .MuiTypography-root": {
                                  padding: "0px 5px",
                                },
                              }}
                              value="male"
                              control={
                                <Radio
                                  onClick={() => {
                                    setGenderExpanded(false);
                                    setRequired({
                                      ...required,
                                      genderRequired: false,
                                    });
                                    setGenderLabel("Male");
                                  }}
                                  sx={{
                                    padding: "0px",
                                    " &.Mui-checked": {
                                      color: "#FDDB31",
                                    },
                                    "& .css-hyxlzm": {
                                      "& .MuiSvgIcon-root": {
                                        width: "24px",
                                        height: "24px",
                                      },
                                    },
                                  }}
                                />
                              }
                              label="Male"
                            />
                            <FormControlLabel
                              sx={{
                                width: "40%",
                                margin: "0px",
                                "& .MuiTypography-root": {
                                  padding: "0px 5px",
                                },
                              }}
                              value="female"
                              control={
                                <Radio
                                  onClick={() => {
                                    setGenderExpanded(false);
                                    setRequired({
                                      ...required,
                                      genderRequired: false,
                                    });
                                    setGenderLabel("Female");
                                  }}
                                  sx={{
                                    padding: "0px",
                                    "&.Mui-checked": {
                                      color: "#FDDB31",
                                    },
                                    "& .css-hyxlzm": {
                                      "& .MuiSvgIcon-root": {
                                        width: "24px",
                                        height: "24px",
                                      },
                                    },
                                  }}
                                />
                              }
                              label="Female"
                            />
                          </RadioGroup>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    {required.genderRequired && (
                      <div className="text-danger">Required</div>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={
                      location.pathname.toString().includes("services-detail")
                        ? 10
                        : 5
                    }
                    sx={{ mx: !isDesktop && "1.5rem" }}
                  >
                    <InputField
                      label="Message*"
                      name="message"
                      onChange={handleFields}
                      value={values.message}
                    />
                  </Grid>

                  <Grid
                    sx={isDesktop ? {} : { mx: "1.5rem" }}
                    sm={10}
                    item
                    xs={12}
                  >
                    <p style={{ fontSize: "16px", color: "#616161" }}>
                      * We do not disclose the personal information or medical
                      records of patients to any third party.
                    </p>
                  </Grid>
                  <Grid
                    sx={isDesktop ? {} : { mx: "1.5rem" }}
                    sm={10}
                    item
                    xs={12}
                  >
                    <button className="apapointment_btn" type="submit">
                      Submit
                    </button>
                  </Grid>
                </>
              ) : (
                ""
              )}
            </Grid>
          </form>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <p>Appointment Date</p>
          <DatePicker
            value={values.scrollDate}
            // onChange={(date) => handleSelect(date)}
            isOpen={isOpen}
            customHeader={"Appointment Date"}
            isPopup={false}
            min={new Date(new Date().setDate(new Date().getDate() - 1))}
            max={new Date(2028, 0, 1)}
            showHeader={true}
            dateFormat={["DD", "MM", "YYYY"]}
            showCaption={true}
            dateConfig={dateConfig}
            onSelect={(date) => handleSelect(date)}
            onCancel={handleCancel}
            cancelText="Cancel"
            confirmText="Set"
          />
        </Modal.Body>
      </Modal>
      <Modal show={emailShow}>
        <Modal.Body>
          {!emailFailed ? (
            <p className="m-0 text-center">
              Thank you for submitting an appointment request with Advance
              Surgicare. Our support team will be in touch with you shortly to
              confirm your appointment.
            </p>
          ) : (
            <p className="m-0 text-center">
              Something went wrong! Please try again
            </p>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Appointment;
