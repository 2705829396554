import React from "react";
import { Link as ScrollLink } from "react-scroll";
import "../Home/home.css";
const Home = ({ Heading, SubHeading, OneLiner, Button }) => {
  return (
    <>
      <div className="home_main">
        {/* <div className="home_main2"> */}
        <div className="home_data">
          <div className="home_text">
            <h4>{Heading}</h4>
            <h6 style={{ color: "#FDDB31" }}>{SubHeading}</h6>
            {/* <p>{OneLiner}</p> */}
          </div>
          <div className="home_btn_div">
            <ScrollLink to="contactus" spy={true} smooth={true} duration={200}>
              <button className="home_btn">{Button}</button>
            </ScrollLink>
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default Home;
